import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthContext';
import { useAccount } from '../../../contexts/AccountContext';

export default function IdentityDocument(props: any) {
  const { auth } = useAuth();
  const { account } = useAccount();
  const [dateOfBirth, setDateOfBirth] = useState<string>('');
  const [documentType, setDocumentType] = useState<string>("Driver's License");
  const [documentNumber, setDocumentNumber] = useState<string>('');
  const [documentFront, setDocumentFront] = useState<File | null>(null);
  const [documentBack, setDocumentBack] = useState<File | null>(null);
  const [issueDate, setIssueDate] = useState<string>('');
  const [expirationDate, setExpirationDate] = useState<string>('');
  const [verificationStatus, setVerificationStatus] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getIdentityDocument = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/identity_documents/me`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data;
    };
    (async () => {
      try {
        const { result } = await getIdentityDocument();
        setDocumentNumber(result.document_number);
        setDocumentType(result.document_type || "Driver's License");
        setDateOfBirth(result.date_of_birth);
        setIssueDate(result.issue_date);
        setExpirationDate(result.expiration_date);
        setVerificationStatus(result.status);
        if (result?.status === 'Verified') return navigate('/portfolio');
      } catch (error) {}
      if (account?.account_type === 'Business Entity')
        return navigate('/tokens');
    })();
  }, []);

  const formatDateForInput = (dateString: string) => {
    if (!dateString) return '';

    const date = new Date(dateString);
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    const year = date.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  const submitIdentityDoc = async () => {
    try {
      setLoading(true);
      // Check if any file is uploaded
      if (
        (documentType === "Driver's License" &&
          (!documentFront || !documentBack)) ||
        (documentType !== "Driver's License" && !documentFront)
      ) {
        return alert(
          'Please upload the required documents for identity verification.'
        );
      }

      // Create a FormData object to handle file uploads
      const formData = new FormData();

      // Append uploaded files to FormData
      if (documentFront) formData.append('dl_front', documentFront);
      if (documentBack) formData.append('dl_back', documentBack);

      // Append other form data
      formData.append('date_of_birth', dateOfBirth);
      formData.append('document_type', documentType);
      formData.append('document_number', documentNumber);
      formData.append('issue_date', issueDate);
      formData.append('expiration_date', expirationDate);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/identity_documents/me/upload`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (response.status === 201) {
        const response = await axios(
          `${process.env.REACT_APP_API_URL}/v1/identity_documents/me`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        );
        setDocumentType(response.data.result?.document_type);
        setDocumentNumber(response.data.result?.document_number);
        setIssueDate(response.data.result?.issue_date);
        setExpirationDate(response.data.result?.expiration_date);
        setVerificationStatus(response.data.result?.status);
        alert('Success. Please wait for your submission to be reviewed.');
        navigate('/onboarding/create-id?walletType=self-custody');
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className="col-lg-6 offset-lg-3 col-md-12 pb-5">
      <h5>Identity Document</h5>
      {account?.account_type === 'Business Entity' ? null : (
        <>
          <div className="mb-3">
            <label className="form-label">Document Type</label>
            <select
              className="form-select"
              onChange={(event) => setDocumentType(event.target.value)}
              defaultValue={documentType || "Driver's License"}
            >
              <option value="Driver's License">Driver's License</option>
              <option value="Passport">Passport</option>
            </select>
          </div>

          {documentType === "Driver's License" && (
            <>
              <div className="mb-3">
                <label className="form-label">Driver's License Front</label>
                <input
                  type="file"
                  className="form-control"
                  accept="image/*"
                  onChange={(e) =>
                    setDocumentFront(e.target.files ? e.target.files[0] : null)
                  }
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Driver's License Back</label>
                <input
                  type="file"
                  className="form-control"
                  accept="image/*"
                  onChange={(e) =>
                    setDocumentBack(e.target.files ? e.target.files[0] : null)
                  }
                />
              </div>
            </>
          )}

          <div className="mb-3">
            <label className="form-label">{documentType} Number</label>
            <input
              type="text"
              className="form-control"
              onChange={(event) => setDocumentNumber(event.target.value)}
              defaultValue={documentNumber}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Date of Birth</label>
            <input
              type="date"
              className="form-control"
              onChange={(event) => setDateOfBirth(event.target.value)}
              defaultValue={formatDateForInput(dateOfBirth)}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Issue Date</label>
            <input
              type="date"
              className="form-control"
              onChange={(event) => setIssueDate(event.target.value)}
              defaultValue={formatDateForInput(issueDate)}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Expiration Date</label>
            <input
              type="date"
              className="form-control"
              onChange={(event) => setExpirationDate(event.target.value)}
              defaultValue={formatDateForInput(expirationDate)}
            />
          </div>
          <div className="d-grid gap-2 mt-4">
            <button
              type="button"
              onClick={submitIdentityDoc}
              className="btn btn-primary"
              disabled={!dateOfBirth || loading}
            >
              {loading ? <i className="fas fa-spinner fa-spin"></i> : 'Submit'}
            </button>
          </div>
        </>
      )}
    </form>
  );
}
