import { useRef, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image';
import ListGroup from 'react-bootstrap/ListGroup';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import numbro from 'numbro';
import { formatRelativeTime, formatAccountSubType } from '../../utils';
import { formatOptions } from '../../constants';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import mercury from '../../assets/mercury.png';

function BalanceSheet({ totals }: any) {
  const chartContainerRef = useRef<HTMLDivElement>(null); // Ref for the chart container
  const [chartWidth, setChartWidth] = useState(0); // State to hold the chart width

  useEffect(() => {
    // Function to update chart width based on the container's width
    const updateChartWidth = () => {
      if (chartContainerRef.current) {
        const width = chartContainerRef.current.offsetWidth;
        setChartWidth(width - 60 * 2); // 48px padding each side
      }
    };

    // Call the function to set the initial width
    updateChartWidth();

    // Add event listener to update width on window resize
    window.addEventListener('resize', updateChartWidth);

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', updateChartWidth);
  }, []);

  // Define the type for the formatter context
  type FormatterContext = {
    total: number;
    y: number;
    axis: any;
  };

  // Set global options for Highcharts
  Highcharts.setOptions({
    lang: {
      thousandsSep: ',', // Ensure numbers have commas
    },
  });

  const options = {
    chart: {
      type: 'column',
      height: 250,
      width: chartWidth,
    },
    title: {
      text: undefined, // No title for the chart
    },
    xAxis: {
      categories: ['April 2024'],
    },
    yAxis: {
      title: {
        text: undefined,
      },
      stackLabels: {
        enabled: true,
        formatter: function (this: FormatterContext): string {
          // Calculate the difference between the two series
          // Assuming that the assets are always the first series (index 0)
          // and liabilities are the second series (index 1)
          const assets = this.axis.series[0].yData[0];
          const liabilities = this.axis.series[1].yData[0];
          const netWorth = assets - liabilities;
          return `$${Highcharts.numberFormat(netWorth, 2)}`;
        },
        style: {
          color: 'grey',
          textOutline: 'none',
        },
      },
    },
    tooltip: {
      valuePrefix: '$',
      shared: true,
    },
    plotOptions: {
      column: {
        // Reduce the width of the bars
        pointPadding: 0.1,
        groupPadding: 0,
        pointWidth: 40,
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          formatter: function (this: FormatterContext): string {
            return `$${Highcharts.numberFormat(this.y, 2)}`;
          },
          style: {},
        },
      },
    },
    series: [
      {
        name: 'Assets',
        data: [totals.asset],
        color: '#2ecc71',
      },
      {
        name: 'Liabilities',
        data: [totals.liability],
        color: '#e74c3c',
      },
    ],
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
  };

  return (
    <div
      ref={chartContainerRef}
      className="border rounded shadow-sm bg-white p-5"
      style={{ overflow: 'hidden' }}
    >
      <div className="mb-4">
        <small className="text-uppercase text-secondary">Balance Sheet</small>
        <div className="balance lead">
          ${numbro(totals.asset).format(formatOptions)}
        </div>
      </div>
      <HighchartsReact highcharts={Highcharts} options={options} />
      {formatRelativeTime(new Date(totals?.lastUpdated || null), new Date())}
    </div>
  );
}

function AccountList({ accounts }: { accounts: any[] }) {
  return (
    <ListGroup>
      {accounts?.length === 0 && (
        <div className="d-flex justify-content-center align-items-center">
          No accounts found
        </div>
      )}
      {accounts?.map((account: any) => {
        return (
          <ListGroup.Item
            key={account.ledger_account_id}
            className="d-flex justify-content-between p-3"
          >
            <div className="d-flex justify-content-start align-items-center">
              <Image
                src={mercury}
                className="me-2"
                width={50}
                height={50}
                roundedCircle
              />
              <div>
                <div className="lead">{account.account_name}</div>
                <div className="text-muted">
                  {formatAccountSubType(account.account_subtype)}
                </div>
              </div>
            </div>
            <div className="text-end">
              <div className="lead">
                ${numbro(account.current_balance).format(formatOptions)}
              </div>
              <div className="text-muted">
                {formatRelativeTime(new Date(account.updated_at), new Date())}
              </div>
            </div>
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
}

function Accounts() {
  const [accounts, setAccounts] = useState([]);
  const [totals, setTotals] = useState({ asset: 0, liability: 0, unknown: 0 });
  const { auth } = useAuth();
  const navigate = useNavigate();

  async function fetchAccounts() {
    try {
      const { data } = await axios({
        url: 'http://localhost:8080/v1/ledgers/me/accounts',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      const categorizedTotals = data.ledger_accounts.reduce(
        (acc: any, account: any) => {
          const category = account.account_type;
          acc[category] =
            (acc[category] || 0) + parseFloat(account.current_balance);
          acc.lastUpdated = account.updated_at;
          return acc;
        },
        { asset: 0, liability: 0, unknown: 0 }
      );
      setAccounts(data.ledger_accounts);
      setTotals(categorizedTotals);
    } catch (error: any) {
      console.log(error);
    }
  }

  /**
   * Fetch accounts
   */
  useEffect(() => {
    (async () => {
      fetchAccounts();
    })();
  }, []);

  return (
    <>
      <Helmet>
        <title>CapSign | Data Room</title>
      </Helmet>
      <Navbar expand="lg" className="bg-transparent">
        <Container fluid>
          <div className="lead">Data Room</div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Button onClick={() => navigate(-1)} variant="">
                <i className="fa fa-xmark"></i>
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="row">
        <div className="my-3 col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="card-text">
                    <h5>Company Information</h5>
                    <strong>Legal Name:</strong> CapSign Inc.
                    <br />
                    <strong>Entity Type:</strong> C Corporation
                    <br />
                    <strong>Jurisdiction:</strong> Wilmington, Delaware
                    <br />
                    <strong>Date Founded:</strong> September 28, 2023
                    <br />
                    <strong>IRS EIN:</strong> 30-1301308
                    <br />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card-text">
                    <h5>Contact Information</h5>
                    <strong>Founder:</strong> Matt Rosendin{' '}
                    <a href="https://linkedin.com/in/mrosendin" target="_blank">
                      <i className="fa-brands fa-linkedin"></i>
                    </a>
                    <br />
                    <strong>Email:</strong>{' '}
                    <a href="mailto:matt@capsign.com" target="_blank">
                      matt@capsign.com
                    </a>
                    <br />
                    <strong>Website:</strong>{' '}
                    <a href="https://capsign.com" target="_blank">
                      https://capsign.com
                    </a>
                    <br />
                    <strong>Headquarters Address:</strong> 101 Polk St #302, San
                    Francisco, CA 94102
                    <br />
                    <strong>Registered Address:</strong> 251 Little Falls Drive,
                    Wilmington, Delaware 19808
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="my-3 col-md-6 col-sm-12">
          <BalanceSheet totals={totals} />
        </div>
        <div className="my-3 col-md-6 col-sm-12">
          <div className="card mb-3">
            <div className="card-body d-flex justify-content-between">
              <div>
                <a
                  href="https://docs.google.com/presentation/d/1qPKuR7hLDOQptaxLGRbtWXK8kdzqvYlmyVVHufcSOrg/edit?usp=sharing"
                  target="_blank"
                  className="text-decoration-none"
                >
                  Investor Presentation{' '}
                  <i className="fa fa-file-powerpoint"></i>
                  <br />
                </a>
              </div>
              <div>
                <a
                  href="https://drive.google.com/drive/folders/14RFFix0HAbfVCTtsynzgkch0_nUzf8Y7?usp=drive_link"
                  target="_blank"
                  className="text-decoration-none"
                >
                  Certificate of Incorporation{' '}
                  <i className="fa fa-certificate"></i>
                </a>
              </div>
              <div>
                <a
                  href="https://docs.google.com/spreadsheets/d/1-LsfcgLC2Hq9n__IA6j7p5puFWoEFYfGaWNIOQmeRfo/edit?usp=sharing"
                  target="_blank"
                  className="text-decoration-none"
                >
                  Cap Table <i className="fa fa-file-excel"></i>
                </a>
                <br />
              </div>
            </div>
          </div>
          <div className="my-3 col-md-12 col-sm-12">
            <AccountList accounts={accounts} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Accounts;
