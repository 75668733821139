// EditOfferingDetails.tsx
import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, InputGroup } from 'react-bootstrap';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { useOffering } from '../../../contexts/OfferingContext';
import { Helmet } from 'react-helmet';

const EditOfferingDetails: React.FC = () => {
  const { auth } = useAuth();
  const { offeringId } = useParams<{ offeringId: string }>();
  const navigate = useNavigate();
  const { offering, setOffering, fetchOffering } = useOffering();

  const [formData, setFormData] = useState<any>({
    name: '',
    description: '',
    exemption: 'Reg CF',
    offeringPrice: 0,
    softCap: '',
    hardCap: '',
    promoImageFile: null,
    useOfFunds: '',
  });

  useEffect(() => {
    if (offeringId) {
      fetchOffering(offeringId);
    }
  }, [offeringId]);

  useEffect(() => {
    if (offering) {
      setFormData({
        name: offering.offering_name,
        description: offering.offering_description,
        exemption: offering.federal_exemption,
        offeringPrice: offering.offering_price,
        softCap: offering.soft_cap,
        hardCap: offering.hard_cap,
        promoImageFile: offering.promo_image_url,
        useOfFunds: offering.use_of_funds,
      });
    }
  }, [offering]);

  const handleFormChange = (
    event: React.ChangeEvent<
      HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
    >
  ) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFormData({ ...formData, promoImageFile: e.target.files[0] });
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('offering_name', formData.name);
    formDataToSend.append('offering_description', formData.description);
    formDataToSend.append('federal_exemption', formData.exemption);
    formDataToSend.append('offering_price', formData.offeringPrice.toString());
    formDataToSend.append('soft_cap', formData.softCap);
    formDataToSend.append('hard_cap', formData.hardCap);
    formDataToSend.append('files', formData.promoImageFile);
    formDataToSend.append('use_of_funds', formData.useOfFunds);

    try {
      await axios({
        url: `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}`,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'multipart/form-data',
        },
        data: formDataToSend,
      });
      fetchOffering(offeringId!);
      navigate(`/offerings/${offeringId}/edit/documents`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container className="my-5">
      <Helmet>Edit Offering | Details</Helmet>
      <div className="row">
        <div className="col-4 offset-4">
          <h2>Edit Offering Details</h2>
          <Form onSubmit={handleSubmit}>
            <h5>Offering Details</h5>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridName">
                <Form.Label>Offering Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Enter offering name"
                  value={formData.name}
                  onChange={handleFormChange}
                  required
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridDescription">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  name="description"
                  rows={3}
                  placeholder="Enter description"
                  value={formData.description}
                  onChange={handleFormChange}
                  required
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridExemption">
                <Form.Label>Exemption</Form.Label>
                <Form.Select
                  name="exemption"
                  value={formData.exemption}
                  onChange={handleFormChange}
                >
                  <option value="Reg CF">Reg CF</option>
                  <option value="Reg D 506(b)">Reg D 506(b)</option>
                  <option value="Reg D 506(c)">Reg D 506(c)</option>
                </Form.Select>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group controlId="formGridOfferingPrice">
                <Form.Label>Offering Price</Form.Label>
                <InputGroup as={Col}>
                  <InputGroup.Text>$</InputGroup.Text>
                  <Form.Control
                    type="number"
                    name="offeringPrice"
                    placeholder="Enter offering price"
                    value={formData.offeringPrice}
                    onChange={handleFormChange}
                    required
                  />
                  <InputGroup.Text>per share</InputGroup.Text>
                </InputGroup>
                <small className="text-muted">
                  The offering price per share.
                </small>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridSoftCap">
                <Form.Label>Target Amount</Form.Label>
                <InputGroup>
                  <InputGroup.Text>$</InputGroup.Text>
                  <Form.Control
                    type="number"
                    name="softCap"
                    placeholder="Enter target amount"
                    value={formData.softCap}
                    onChange={handleFormChange}
                    required
                  />
                </InputGroup>
              </Form.Group>
              <small className="text-muted">
                This is the minimum amount to raise.
              </small>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridHardCap">
                <Form.Label>Maximum Amount</Form.Label>
                <InputGroup>
                  <InputGroup.Text>$</InputGroup.Text>
                  <Form.Control
                    type="number"
                    name="hardCap"
                    placeholder="Enter maximum amount"
                    value={formData.hardCap}
                    onChange={handleFormChange}
                    required
                  />
                </InputGroup>
              </Form.Group>
              <small className="text-muted">
                Amount to raise beyond the target amount.
              </small>
            </Row>
            <Row className="mb-3">
              {formData.promoImageFile && (
                <img src={formData.promoImageFile} alt="" />
              )}
              <Form.Group as={Col} controlId="formGridPromoImageFile">
                <Form.Label>Promo Image</Form.Label>
                <Form.Control
                  type="file"
                  name="promoImageFile"
                  onChange={handleFileChange}
                />
              </Form.Group>
              <small className="text-muted">Should be 600x300 pixels.</small>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridUseOfFunds">
                <Form.Label>Use of Funds</Form.Label>
                <Form.Control
                  as="textarea"
                  name="useOfFunds"
                  rows={3}
                  placeholder="Enter use of funds"
                  value={formData.useOfFunds}
                  onChange={handleFormChange}
                  required
                />
              </Form.Group>
            </Row>
            <Button variant="primary" type="submit">
              Save and Continue
            </Button>
          </Form>
        </div>
      </div>
    </Container>
  );
};

export default EditOfferingDetails;
