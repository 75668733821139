import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { useOffering } from '../../../contexts/OfferingContext';
import { useError } from '../../../contexts/ErrorContext';
import PDFViewer from './PDFViewer';

export default function OfferingFinancials() {
  const [pdfData, setPdfData] = useState<any>(undefined);
  const { auth } = useAuth();
  const { offeringId } = useParams<{ offeringId: string }>();
  const { offering } = useOffering();
  const navigate = useNavigate();
  const [documents, setDocuments] = useState<any[]>([]);
  const [requiredDocuments, setRequiredDocuments] = useState<string[]>([
    'Form C',
    'Financial Statements',
    'Subscription Agreement',
    'Pitch Deck',
    'Use of Funds Statement',
    'Risk Factors Document',
    'Capitalization Table',
  ]);
  const { setError } = useError();

  /**
   * Fetch the current offering financial statements
   */
  useEffect(() => {
    const fetchOfferingDocuments = async () => {
      try {
        if (pdfData) return;

        let response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/documents`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        );

        // Financial Statements
        const documentId = response.data.result.filter(
          (r: any) => r.document_type === 'Financial Statements'
        )[0]?.document_id;

        response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/documents/${documentId}`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
            responseType: 'arraybuffer',
          }
        );
        setPdfData({ data: new Uint8Array(response.data) });
      } catch (error) {
        console.error('Error fetching offering:', error);
      }
    };

    fetchOfferingDocuments();
  }, []);

  return (
    <>
      <h3>Highlights</h3>
      <p className="border rounded p-2">
        From an initial $50k investment, CapSign spent ~$31k from Feb '24 to Jun
        '24 to launch its first product, an on-chain equity crowdfunding portal.
      </p>
      <PDFViewer data={pdfData} />;
    </>
  );
}
