import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';
import numbro from 'numbro';
import { contracts, formatOptions } from '../../../constants';
import { useError } from '../../../contexts/ErrorContext';
import { decimals, subscriptionTokenAddress } from '../../../utils';
import {
  useAccount,
  useChainId,
  usePublicClient,
  useWriteContract,
} from 'wagmi';
import { Abi, parseUnits } from 'viem';
import { decodeEvmTransactionErrorResult } from '../../../lib';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthContext';

export default function SellForm({ show, handleClose, onListingSuccess }: any) {
  const { auth } = useAuth();
  const { tokenId } = useParams();
  const { address: account } = useAccount();
  const chainId = useChainId();
  const publicClient = usePublicClient();
  const [securityId, setSecurityId] = useState('');
  const [price, setPrice] = useState('');
  const [quantity, setQuantity] = useState('');
  const [fee, setFee] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const { setError } = useError();
  const [certificates, setCertificates] = useState<any[]>([]);
  const [max, setMax] = useState<number>();

  const tokenContract = contracts[chainId!]?.Token;
  const auctionContract = contracts[chainId!]?.Auction;

  const tokenAddress = tokenId as `0x${string}`;
  const tokenAbi = tokenContract.abi as Abi;

  const { writeContractAsync: createListing } = useWriteContract();
  const { writeContractAsync: approve } = useWriteContract();

  useEffect(() => {
    if (price && quantity) {
      const calculatedFee = parseFloat(price) * parseFloat(quantity) * 0.05;
      setFee(calculatedFee);
    } else {
      setFee(0);
    }
  }, [price, quantity]);

  /**
   * @function placeOrder
   * @param e
   * @returns
   */
  const placeOrder = async (e: any) => {
    try {
      e.preventDefault();
      if (!price || !quantity) {
        alert('Please set a price and quantity.');
        return;
      }
      if (!isChecked) {
        alert('Please acknowledge the disclosure.');
        return;
      }

      const complianceAddress = await publicClient?.readContract({
        address: tokenAddress,
        abi: tokenAbi,
        functionName: 'compliance',
      });

      const identityRegistryAddress = await publicClient?.readContract({
        address: tokenAddress,
        abi: tokenAbi,
        functionName: 'identityRegistry',
      });

      const canTransfer = await publicClient?.readContract({
        address: complianceAddress as `0x${string}`,
        abi: contracts[chainId!]?.ModularCompliance.abi as Abi,
        functionName: 'canTransfer',
        args: [account, auctionContract.address, securityId, quantity],
      });

      if (!canTransfer) {
        alert('Cannot transfer tokens.');
        return;
      }

      const isVerifiedAccount = await publicClient?.readContract({
        address: identityRegistryAddress as `0x${string}`,
        abi: contracts[chainId!]?.IdentityRegistry.abi as Abi,
        functionName: 'isVerified',
        args: [account],
      });

      const isVerifiedAuction = await publicClient?.readContract({
        address: identityRegistryAddress as `0x${string}`,
        abi: contracts[chainId!]?.IdentityRegistry.abi as Abi,
        functionName: 'isVerified',
        args: [auctionContract.address],
      });

      if (!isVerifiedAccount || !isVerifiedAuction) {
        alert('Account or Auction is not verified.');
        return;
      }

      const security: any = await publicClient?.readContract({
        address: tokenAddress,
        abi: tokenAbi,
        functionName: 'securities',
        args: [securityId],
      });

      const allowance: any = await publicClient?.readContract({
        address: tokenAddress,
        abi: tokenAbi,
        functionName: 'allowance',
        args: [account, security.class, auctionContract.address],
      });

      if (allowance < BigInt(quantity)) {
        const approveTx = await approve({
          address: tokenAddress,
          abi: tokenAbi,
          functionName: 'approve',
          args: [auctionContract.address, security.class, quantity],
        });
        await publicClient?.waitForTransactionReceipt({ hash: approveTx });
      }

      const nowInSeconds = Math.floor(new Date().getTime() / 1000);
      const thirtyDaysInSeconds = 30 * 24 * 60 * 60;
      const expiresAt = nowInSeconds + thirtyDaysInSeconds;
      const args = [
        tokenAddress,
        subscriptionTokenAddress[chainId!],
        securityId,
        BigInt(quantity),
        parseUnits(price, decimals[chainId!]),
        expiresAt,
      ];

      const gas = await publicClient?.estimateContractGas({
        address: auctionContract.address as `0x${string}`,
        abi: auctionContract.abi as Abi,
        functionName: 'createListing',
        args: args,
        account: account,
      });

      await createListing({
        address: auctionContract.address as `0x${string}`,
        abi: auctionContract.abi as Abi,
        functionName: 'createListing',
        args: args,
        account,
        gas,
      });

      alert(
        `Listing for ${quantity} of ${tokenId} at $${price} placed successfully!`
      );
      setPrice('');
      setQuantity('');
      setFee(0);
      setIsChecked(false);
      onListingSuccess();
      handleClose(); // Close the modal after placing the bid
    } catch (error: any) {
      const result = decodeEvmTransactionErrorResult({
        error,
        abi: auctionContract.abi as Abi,
      });
      console.log({ result });
      console.error(error);
      setError(error);
    }
  };

  const getTotal = () => {
    if (!price || !quantity) return '--';
    return numbro(
      Number((parseFloat(price) * parseFloat(quantity) - fee).toFixed(2))
    ).format(formatOptions);
  };

  const handleCheckboxChange = (event: any) => {
    setIsChecked(event.target.checked);
  };

  /**
   * Fetch certificates
   */
  useEffect(() => {
    (async () => {
      try {
        const url = `${process.env.REACT_APP_API_URL}/v1/accounts/me/certificates`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        });
        const result = response.data.result;
        setCertificates(result);
        setSecurityId(result[0].balance_security_hash);
        const security: any = await publicClient?.readContract({
          address: tokenAddress,
          abi: tokenAbi,
          functionName: 'securities',
          args: [result[0].balance_security_hash],
        });
        setMax(Number(security.amount));
      } catch (error: any) {
        console.error(error);
      }
    })();
  }, [auth?.token, chainId, account]);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton className="p-4">
        <Modal.Title>Make a Listing</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Form onSubmit={placeOrder}>
          <div className="row">
            <div className="col-12">
              <Form.Group className="mb-3">
                <Form.Label>Security ID</Form.Label>
                <Form.Select
                  value={securityId}
                  onChange={async (e) => {
                    try {
                      const security: any = await publicClient?.readContract({
                        address: tokenAddress,
                        abi: tokenAbi,
                        functionName: 'securities',
                        args: [securityId],
                      });
                      setMax(security.quantity);
                      // TODO: Set max balance
                      setSecurityId(e.target.value);
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                  required
                >
                  <option value="" disabled>
                    Select a security ID
                  </option>
                  {certificates.map((certificate) => (
                    <option
                      key={certificate.certificate_id}
                      value={certificate.balance_security_hash}
                    >
                      {certificate.balance_security_hash}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <Form.Group className="mb-3">
                <Form.Label>Number of shares</Form.Label>
                <Form.Control
                  type="number"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                />
                <small className="text-muted">
                  Max: {numbro(max).format({ thousandSeparated: true })}
                </small>
              </Form.Group>
            </div>
            <div className="col-6">
              <Form.Group className="mb-3">
                <Form.Label>Price per share</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="number"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                  <InputGroup.Text>USD</InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </div>
          </div>
          <Form.Group className="mb-3">
            <Form.Label>Net price per share*</Form.Label>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                type="text"
                value={
                  price && quantity
                    ? (
                        (parseFloat(price) * parseFloat(quantity) - fee) /
                        parseFloat(quantity)
                      ).toFixed(2)
                    : ''
                }
                readOnly
              />
            </InputGroup>
            <Form.Text className="text-muted">
              * This is the estimated price per share after deducting the
              CapSign Transaction Fee. There may be other charges that you will
              have to incur in order to complete this transaction.
            </Form.Text>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Total Proceeds</Form.Label>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control type="text" value={getTotal()} readOnly />
            </InputGroup>
          </Form.Group>
        </Form>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="disclosureCheckbox"
            required
            onChange={handleCheckboxChange}
            checked={isChecked}
          />
          <label className="form-check-label" htmlFor="disclosureCheckbox">
            <small>
              I agree to the&nbsp;
              <a
                href="https://app.termly.io/document/disclaimer/21e6271b-0a31-4d8e-b5e6-58397463bc96"
                target="_blank"
              >
                terms and conditions
              </a>{' '}
              of the marketplace and confirm my bona fide interest in pursuing a
              transaction on these terms.
            </small>
          </label>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={placeOrder} disabled={loading}>
          {loading ? 'Placing...' : 'Place Order'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
