import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthContext';
import { contracts } from '../../../constants';
import { useNavigate } from 'react-router-dom';
import { useError } from '../../../contexts/ErrorContext';
import { capitalizeString } from '../../../utils';
import {
  useAccount,
  useChainId,
  usePublicClient,
  useWriteContract,
} from 'wagmi';
import { zeroAddress, Abi, parseEventLogs } from 'viem';
import { config } from '../../../config';

interface TokenDetails {
  owner: `0x${string}`;
  name: string;
  symbol: string;
  decimals: bigint;
  irs: `0x${string}`;
  ONCHAINID: `0x${string}`;
  irAgents: `0x${string}`[];
  tokenAgents: `0x${string}`[];
  complianceModules: `0x${string}`[];
  complianceSettings: `0x${string}`[];
}

interface ClaimDetails {
  claimTopics: bigint[];
  issuers: `0x${string}`[];
  issuerClaims: bigint[][];
}

export default function CreateToken() {
  const [assets, setAssets] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState('');
  const { auth } = useAuth();
  const { setError } = useError();
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState({
    name: '',
    symbol: '',
    entity_id: '',
    security_id: '',
  });
  const chainId = useChainId();
  const account = useAccount();
  const [defaultShareClass, setDefaultShareClass] = useState<any>('Common');
  const navigate = useNavigate();
  const [discountRate, setDiscountRate] = useState(0);
  const [valuationCap, setValuationCap] = useState('');
  const [mfnProvision, setMfnProvision] = useState(false);
  const [minInvestment, setMinInvestment] = useState('');
  const [maxRaiseAmount, setMaxRaiseAmount] = useState('');
  const publicClient = usePublicClient({ config });

  const {
    data: txHash,
    error,
    writeContractAsync: deployTREXSuite,
  } = useWriteContract();

  useEffect(() => {
    const fetchEntity = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/business_entities/me`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        );
        const name = response.data.result.legal_name;
        const entity_id = response.data.result.entity_id;
        setToken({
          ...token,
          entity_id,
          name: name,
          symbol: name.substring(0, 3).toUpperCase(),
        });
      } catch (error) {
        console.error(error);
      }
    };

    // Fetch assets
    const fetchAssets = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/assets`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        );
        const ENABLED_ASSET_SUBTYPES = ['stock'];
        const filteredAssets = response.data.result.filter((asset: any) =>
          ENABLED_ASSET_SUBTYPES.includes(asset.asset_subtype)
        );
        setAssets(filteredAssets);
      } catch (error) {
        console.error(error);
      }
    };

    fetchEntity();
    fetchAssets();
  }, [auth?.token]);

  /**
   * Generate security ID
   */
  React.useEffect(() => {
    (async () => {
      try {
        if (token.symbol.length === 3 && selectedAsset) {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/v1/business_entities/${token.entity_id}/security_id?symbol=${token.symbol}&asset=${selectedAsset}`,
            {
              headers: {
                Authorization: `Bearer ${auth?.token}`,
              },
            }
          );
          setToken({ ...token, security_id: response.data.security_id });
        }
      } catch (error) {
        console.log('failed generated security id');
        console.log(error);
      }
    })();
  }, [token.symbol, selectedAsset]);

  const handleMaxRaiseAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMaxRaiseAmount(event.target.value.replace(/[^0-9.]/g, '')); // Allowing numbers and dots for decimal amounts
  };

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDefaultShareClass({ ...token, defaultShareClass: event.target.value });
  };

  const handleAssetChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedAsset(event.target.value);
  };

  const handleDiscountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDiscountRate(Math.max(0, Math.min(100, Number(event.target.value))));
  };

  const handleValuationCapChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValuationCap(event.target.value.replace(/[^0-9]/g, '')); // Ensure only numbers
  };

  const handleMfnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMfnProvision(event.target.checked);
  };

  const handleMinInvestmentChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMinInvestment(event.target.value.replace(/[^0-9.]/g, '')); // Allowing numbers and dots for decimal amounts
  };

  /**
   * @function handleSubmitToken
   * @param event
   * @returns
   */
  const handleSubmitToken = async (event: any) => {
    event.preventDefault();

    setLoading(true);

    let tokenName = token.name; // default
    let tokenSymbol = token.symbol; // default

    if (selectedAsset === 'stock') {
      // Check if entity already has a capital stock token
      try {
        // TODO: make use of query params to get token
        const tokensResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/business_entities/me/tokens?chain_id=${chainId}`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        );
        const capitalStockTokenExists = tokensResponse.data.result.some(
          (token: any) => token.asset_subtype === 'stock'
        );

        if (capitalStockTokenExists) {
          // Inform the user that a capital stock token already exists
          setError({
            message:
              'Your entity already has a capital stock token. You cannot create more than one.',
          });
          setLoading(false);
          return; // Stop execution
        }
      } catch (error) {
        console.error('Error fetching existing tokens:', error);
        setError({
          message: 'Failed to verify existing tokens. Please try again later.',
        });
        setLoading(false);
        return; // Stop execution
      }

      const salt = new Date().toISOString(); // This needs to be unique for each deployment
      const tokenDetails: TokenDetails = {
        name: tokenName,
        symbol: tokenSymbol,
        decimals: BigInt(0),
        irAgents: [account?.address! as `0x${string}`],
        tokenAgents: [account?.address! as `0x${string}`],
        complianceModules: [],
        complianceSettings: [],
        ONCHAINID: zeroAddress as `0x${string}`,
        owner: account?.address! as `0x${string}`,
        irs: zeroAddress as `0x${string}`,
      };

      const claimDetails: ClaimDetails = {
        claimTopics: [], // List of claim topics
        issuers: [], // List of issuer addresses (TODO: rename "issuers" to "attestors" to avoid confusion)
        issuerClaims: [], // Corresponding claims for each issuer/attestor
      };
      try {
        const args = [salt, tokenDetails, claimDetails];
        const gas = await publicClient?.estimateContractGas({
          address: contracts[chainId].TREXFactory.address as `0x${string}`,
          abi: contracts[chainId].TREXFactory.abi as Abi,
          functionName: 'deployTREXSuite',
          args,
          account: account.address,
        });
        const hash = await deployTREXSuite({
          address: contracts[chainId].TREXFactory.address as `0x${string}`,
          abi: contracts[chainId].TREXFactory.abi as Abi,
          functionName: 'deployTREXSuite',
          args,
          gas: gas,
        });
        const receipt = await publicClient?.waitForTransactionReceipt({
          hash: hash,
        });

        const logs = parseEventLogs({
          abi: contracts[chainId].TREXFactory.abi as Abi,
          logs: receipt?.logs!,
        });
        const event = logs.filter(
          (l) => l.eventName === 'TREXSuiteDeployed'
        )[0];

        const { _token: tokenAddress, _ir: ir } = event.args as any;

        // Save token to database
        await axios({
          url: `${process.env.REACT_APP_API_URL}/v1/business_entities/me/tokens`,
          method: 'POST',
          data: {
            address: tokenAddress,
            name: token.name,
            symbol: token.security_id,
            type: selectedAsset,
            security_id: token.security_id,
            registry: ir,
            chain_id: chainId,
            identity_address: zeroAddress,
          },
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        });

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/business_entities/me/tokens?chain_id=${chainId}`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        );
        const result = response.data.result;
        navigate('/equity');
      } catch (error: any) {
        let _message = error?.message;
        console.log(_message);
        setError(error);
      } finally {
        setLoading(false);
      }
    }
    // else if (selectedAsset === 'safe') {
    //   try {
    //     // TODO: deploy an identity registry
    //     tokenName = `${token.name} SAFE`;
    //     const signer = library?.getSigner();
    //     const _safe = contracts[chainId!].SAFE;
    //     const safeFactory = new ContractFactory(_safe.abi, _safe.bytecode);
    //     const safe = await safeFactory.connect(signer!).deploy(
    //       contracts[chainId!].USD.address,
    //       0,
    //       0,
    //       false,
    //       '',
    //       0, // TODO: set a registry
    //       ''
    //     );
    //     await safe.deployed();

    //     const url = `${process.env.REACT_APP_API_URL}/v1/business_entities/me/tokens?chain_id=${chainId}`;

    //     await axios({
    //       url,
    //       method: 'POST',
    //       data: {
    //         address: safe.address,
    //         name: token.name,
    //         symbol: token.security_id,
    //         type: selectedAsset,
    //         security_id: token.security_id,
    //         registry: '', // TODO: set a registry
    //         // discountRate,
    //         // valuationCap: Number(valuationCap),
    //         // mfnProvision
    //         chain_id: chainId,
    //       },
    //       headers: {
    //         Authorization: `Bearer ${auth?.token}`,
    //       },
    //     });
    //     const response = await axios.get(url, {
    //       headers: {
    //         Authorization: `Bearer ${auth?.token}`,
    //       },
    //     });
    //     const result = response.data.result;
    //     setTokens(result);
    //     navigate('/equity');
    //   } catch (error) {
    //     setError(error);
    //     console.error('Deployment error:', error);
    //   } finally {
    //     setLoading(false);
    //   }
    // }
  };

  const code: { [key: string]: string } = {
    stock: 'ST',
    safe: 'SF',
  };

  return (
    <>
      <Container className="py-5">
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <h5 className="mb-4">Create Token</h5>
            <Form onSubmit={handleSubmitToken}>
              {/* Asset dropdown */}
              <Form.Group controlId="formAsset" className="mb-3">
                <Form.Label>Asset</Form.Label>
                <Form.Select
                  aria-label="Asset select"
                  value={selectedAsset}
                  onChange={handleAssetChange}
                >
                  <option>Select an asset</option>
                  {assets.map((asset: any) => (
                    <option key={asset.asset_id} value={asset.asset_subtype}>
                      {capitalizeString(
                        asset.asset_subtype.split('_').join(' ')
                      )}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group controlId="formCompanyName" className="mb-3">
                <Form.Label>Issuer Name</Form.Label>
                <Form.Control
                  type="text"
                  readOnly
                  disabled
                  defaultValue={token.name}
                />
              </Form.Group>
              <Form.Group controlId="uniqueID" className="mb-3">
                <Form.Label>Unique ID</Form.Label>
                <Form.Control
                  type="text"
                  value={
                    !selectedAsset
                      ? `${token.symbol}${code[selectedAsset] || '░░'}0011`
                      : token.security_id
                  }
                  readOnly
                  disabled
                  maxLength={9}
                />
              </Form.Group>
              {selectedAsset === 'safe' && (
                <>
                  <Form.Group controlId="formDiscount" className="mb-3">
                    <Form.Label>Discount Rate (%)</Form.Label>
                    <Form.Control
                      type="number"
                      value={discountRate}
                      onChange={handleDiscountChange}
                      min="0"
                      max="100"
                    />
                    <Form.Text className="text-muted">
                      Enter a discount rate from 0 to 100.
                    </Form.Text>
                  </Form.Group>
                  <Form.Group controlId="formValuationCap" className="mb-3">
                    <Form.Label>Valuation Cap</Form.Label>
                    <Form.Control
                      type="text"
                      value={valuationCap}
                      onChange={handleValuationCapChange}
                      placeholder="Enter valuation cap"
                    />
                    <Form.Text className="text-muted">
                      Enter a positive number for the valuation cap. Leave blank
                      for no valuation cap.
                    </Form.Text>
                  </Form.Group>
                  <Form.Group controlId="formMfnProvision" className="mb-3">
                    <Form.Check
                      type="checkbox"
                      label="Most Favored Nation Clause"
                      checked={mfnProvision}
                      onChange={handleMfnChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formMinInvestment" className="mb-3">
                    <Form.Label>Minimum Investment Amount</Form.Label>
                    <Form.Control
                      type="text"
                      value={minInvestment}
                      onChange={handleMinInvestmentChange}
                      placeholder="Enter minimum investment amount"
                    />
                    <Form.Text className="text-muted">
                      Enter the minimum amount required to invest. Leave blank
                      for no minimum.
                    </Form.Text>
                  </Form.Group>
                  <Form.Group controlId="formMaxRaiseAmount" className="mb-3">
                    <Form.Label>Maximum Raise Amount</Form.Label>
                    <Form.Control
                      type="text"
                      value={maxRaiseAmount}
                      onChange={handleMaxRaiseAmountChange}
                      placeholder="Enter maximum fundraise amount"
                    />
                    <Form.Text className="text-muted">
                      Enter the maximum amount of funds to raise. Leave blank
                      for no maximum.
                    </Form.Text>
                  </Form.Group>
                </>
              )}
              {selectedAsset === 'stock' && (
                <Form.Group controlId="formShareClass" className="mb-3">
                  <Form.Label>Default Share Class</Form.Label>
                  <Form.Control type="text" value="Common" readOnly disabled />
                </Form.Group>
              )}
              <Button
                variant="primary"
                type="submit"
                className="mb-3"
                disabled={loading || !selectedAsset}
              >
                {loading ? (
                  <i className="fas fa-spinner fa-spin" />
                ) : (
                  'Create Token'
                )}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}
