import { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useOnboarding } from '../../contexts/OnboardingContext';

export default function AccountType(props: any) {
  const [accountType, setAccountType] = useState<string>();
  const { auth } = useAuth();
  const { onboarding } = useOnboarding();
  const navigate = useNavigate();

  useEffect(() => {
    if (onboarding?.accountType) setAccountType(onboarding?.accountType);
  }, [onboarding]);

  const onSubmit = async (event: any) => {
    event.preventDefault(); // Prevent default form submission behavior

    // If it's already set, continue to next part
    if (onboarding?.accountType) {
      if (onboarding.accountType === 'Individual') {
        return navigate('/onboarding/individual/name');
      } else {
        return navigate('/onboarding/entity/details');
      }
    }

    try {
      const data = { account_type: accountType };
      await axios.patch(
        process.env.REACT_APP_API_URL + '/v1/accounts/me/type',
        data,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );

      if (accountType === 'Individual') {
        return navigate('/onboarding/individual/name');
      } else {
        return navigate('/onboarding/entity/details');
      }
    } catch (error: any) {
      console.error('Error creating account:', error);
      // Handle error case, possibly setting an error state and displaying it to the user
    }
  };

  return (
    <div className="row">
      <Form className="col-lg-4 offset-lg-4 col-md-12 py-5" onSubmit={onSubmit}>
        <Form.Group controlId="accountTypeSelect">
          <Form.Label>Select Account Type</Form.Label>
          <Form.Select
            disabled={onboarding?.accountType ? true : false}
            value={accountType || onboarding?.accountType || ''}
            onChange={(event: any) => setAccountType(event.target.value)}
          >
            <option value="">Please select</option>
            <option value="Individual">Individual</option>
            <option value="Business Entity">Business Entity</option>
          </Form.Select>
        </Form.Group>
        {accountType === 'Individual' && (
          <Form.Text className="text-muted">
            This account type is for personal investing or selling shares from
            your personal holdings.
          </Form.Text>
        )}
        {accountType === 'Business Entity' && (
          <Form.Text className="text-muted">
            This account type is for users acting on behalf of a business
            entity.
          </Form.Text>
        )}
        <Button
          variant="primary"
          className="mt-5 w-100"
          type="submit"
          disabled={!accountType}
        >
          Continue
        </Button>
      </Form>
    </div>
  );
}
