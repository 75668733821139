import axios from 'axios';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';

const AppMarketplace = () => {
  const { auth } = useAuth();
  const [searchTerm, setSearchTerm] = React.useState('');
  const [apps, setApps] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState('');

  React.useEffect(() => {
    (async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/apps`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      setApps(response.data.result);
    })();
  }, []);

  const handleSearchChange = (event: any) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleCategoryChange = (category: string) => {
    setSelectedCategory(category);
  };

  const filteredApps = apps.filter((app: any) => {
    return (
      (app.app_name.toLowerCase().includes(searchTerm) ||
        app.app_short_description.toLowerCase().includes(searchTerm)) &&
      (selectedCategory === '' || app.categories.includes(selectedCategory))
    );
  });

  return (
    <div>
      {/* Header: Hero section */}
      <header className="container mt-5">
        <div className="row justify-content-md-between align-items-md-center">
          <div className="col-md-5 mb-7 mb-md-0">
            <h3>App Marketplace</h3>
            <p>
              Find tools to improve your workflow&mdash;right within CapSign.
            </p>
          </div>
          <div className="col-md-5">
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search for apps"
                aria-label="Search for apps"
                onChange={handleSearchChange}
              />
              <span className="input-group-text">
                <i className="fa fa-search"></i>
              </span>
            </div>
          </div>
        </div>
      </header>

      {/* Main content: Card Grid */}
      <main className="container content-space-b-2 content-space-b-lg-3 mt-4">
        <div className="row">
          <div className="col-lg-3">
            {/* Sidebar with Navigation */}
            <div className="navbar-expand-lg">
              <button
                className="navbar-toggler btn btn-white rounded mb-3"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarVerticalNavMenu"
              >
                <span className="d-flex justify-content-between align-items-center">
                  <span className="text-dark">Menu</span>
                  <i className="bi-list"></i>
                </span>
              </button>
              <div
                id="navbarVerticalNavMenu"
                className="collapse navbar-collapse"
              >
                <ul className="nav flex-column">
                  <li className="nav-item">
                    <button
                      className="btn nav-link"
                      onClick={() => handleCategoryChange('')}
                    >
                      All{' '}
                      <span className="badge bg-secondary">{apps.length}</span>
                    </button>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link disabled" href="#">
                      Top rated
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link disabled" href="#">
                      Featured
                    </a>
                  </li>
                  {/* Categories */}
                  <li className="nav-item">
                    <h6 className="mt-4 mb-2">Categories</h6>
                    <ul className="nav flex-column">
                      {[
                        'Analytics',
                        'Custody',
                        'Compliance',
                        'Documents',
                        'Equity',
                        'Gateway',
                        'Identity',
                        'Security',
                        'Reporting',
                      ].map((category) => (
                        <li key={category} className="nav-item">
                          <button
                            className="btn nav-link"
                            onClick={() => handleCategoryChange(category)}
                          >
                            {category}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-9">
            {/* Cards for apps */}
            <div className="row row-cols-1 row-cols-md-3 g-4">
              {filteredApps.map((app: any) => (
                <div key={app.app_id} className="col">
                  <NavLink
                    to={`${app.app_id}/info`}
                    className="text-decoration-none"
                  >
                    <div className="card h-100">
                      <img
                        src={app.app_banner_url}
                        className="card-img-top"
                        alt={app.app_name}
                      />
                      <div className="card-body border-top">
                        <h5 className="card-title">{app.app_name}</h5>
                        <p className="card-text">{app.app_short_description}</p>
                      </div>
                    </div>
                  </NavLink>
                </div>
              ))}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AppMarketplace;
