import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { useParams } from 'react-router-dom';
import { useError } from '../../../contexts/ErrorContext';
import { ListGroup, ListGroupItem, Container, Row, Col } from 'react-bootstrap';

export default function OfferingDocuments() {
  const { auth } = useAuth();
  const { offeringId } = useParams<{ offeringId: string }>();
  const { setError } = useError();
  const [documents, setDocuments] = useState<any[]>([]);
  const [documentUris, setDocumentUris] = useState<{ [key: string]: string }>(
    {}
  );

  /**
   * Fetch the current offering documents
   */
  useEffect(() => {
    const fetchOfferingDocuments = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/documents`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        );
        setDocuments(response.data.result);

        const documentUris: { [key: string]: string } = {};
        response.data.result.forEach((document: any) => {
          documentUris[document.document_type] = document.document_uri;
        });
        setDocumentUris(documentUris);
      } catch (error: any) {
        console.error('Error fetching documents:', error);
        if (error.response) {
          setError(error.response.data.message);
        }
      }
    };

    fetchOfferingDocuments();
  }, [auth, offeringId, setError]);

  return (
    <Container>
      <Row>
        <Col>
          <h3>Offering Documents</h3>
          <ListGroup>
            {[
              'Form C',
              'Subscription Agreement',
              'Capitalization Table',
              'Risk Factors Document',
              'Use of Funds Statement',
            ].map((docType) => (
              <ListGroupItem key={docType}>
                <strong>{docType}:</strong>{' '}
                {documentUris[docType] ? (
                  <a
                    href={documentUris[docType]}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Document
                  </a>
                ) : (
                  'Not Available'
                )}
              </ListGroupItem>
            ))}
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
}
