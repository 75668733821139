import { http, createConfig } from 'wagmi';
import { base, baseSepolia, Chain, hardhat } from 'wagmi/chains';
import { coinbaseWallet } from 'wagmi/connectors';

export const config = createConfig({
  chains: (() => {
    const temp: [Chain, ...Chain[]] | [] = [base, baseSepolia];
    if (process.env.NODE_ENV === 'development') temp.unshift(hardhat); // insert hardhat P1
    const chains: readonly [Chain, ...Chain[]] | [] = temp;
    return chains;
  })(),
  connectors: [coinbaseWallet({ appName: 'CapSign', preference: 'all' })],
  transports: {
    [base.id]: http(),
    [baseSepolia.id]: http(),
    [hardhat.id]: http(),
  },
});
