import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { useOffering } from '../../../contexts/OfferingContext';
import { useError } from '../../../contexts/ErrorContext';

const EditOfferingDocuments: React.FC = () => {
  const { auth } = useAuth();
  const { offeringId } = useParams<{ offeringId: string }>();
  const { offering } = useOffering();
  const navigate = useNavigate();
  const [documents, setDocuments] = useState<any[]>([]);
  const [requiredDocuments, setRequiredDocuments] = useState<string[]>([
    'Form C',
    'Financial Statements',
    'Subscription Agreement',
    'Pitch Deck',
    'Use of Funds Statement',
    'Risk Factors Document',
    'Capitalization Table',
  ]);
  const { setError } = useError();

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/documents`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        );
        setDocuments(response.data.result);
      } catch (error) {
        console.error('Error fetching documents:', error);
      }
    };

    fetchDocuments();
  }, [offeringId]);

  const handleFileChange = (index: number, event: React.ChangeEvent<any>) => {
    if (event.target.files) {
      const newDocuments = [...documents];
      newDocuments[index] = {
        document_type: event.target.files[0].name
          .replace('_', ' ')
          .split('.')[0],
        file: event.target.files[0],
        local_uri: URL.createObjectURL(event.target.files[0]),
      };
      setDocuments(newDocuments);
    }
  };

  const addDocument = () => {
    setDocuments([...documents, { type: 'Material Agreement', file: null }]);
  };

  const removeDocument = (index: number) => {
    const newDocuments = documents.slice();
    newDocuments.splice(index, 1);
    setDocuments(newDocuments);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      for (const document of documents) {
        if (document.file) {
          console.log({ document });
          const formData = new FormData();
          formData.append('files', document?.file);
          formData.append('type', document?.document_type);

          await axios({
            url: `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/document`,
            method: 'PUT',
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'Content-Type': 'multipart/form-data',
            },
            data: formData,
          });
        }
      }
      navigate(`/offerings/${offeringId}/edit/payment_terms`);
    } catch (error: any) {
      console.error(error);
      if (error.response) {
        setError(error.response.data.message);
      }
    }
  };

  const getFileInput = (docType: string, index: number) => {
    const doc = documents.find((d) => d?.document_type === docType);
    return (
      <Row className="mb-3" key={index}>
        <Form.Group as={Col}>
          <Form.Label>{docType}</Form.Label>
          <Form.Control
            id={`${docType.replace(/ /g, '_')}`}
            type="file"
            onChange={(e) => handleFileChange(index, e)}
          />
          {doc && (
            <div>
              <small className="text-muted">
                Existing File: {doc.document_uri || doc.local_uri}
              </small>
            </div>
          )}
        </Form.Group>
      </Row>
    );
  };

  return (
    <Container className="my-5">
      <div className="row">
        <div className="col-8 offset-2">
          <h2>Edit Offering Documents</h2>
          <Form onSubmit={handleSubmit}>
            {requiredDocuments.map((docType, index) =>
              getFileInput(docType, index)
            )}
            <h4>Additional Material Agreements and Contracts</h4>
            {documents
              .filter((doc) => doc.document_type === 'Material Agreement')
              .map((doc, index) => (
                <div key={index} className="mb-3 border p-3 rounded">
                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label>Material Agreement</Form.Label>
                      <Form.Control
                        id={`formGridDocumentFile${index}`}
                        type="file"
                        onChange={(e) => handleFileChange(index, e)}
                      />
                      {doc && (
                        <div>
                          <small className="text-muted">
                            Existing File: {doc.document_uri}
                          </small>
                        </div>
                      )}
                    </Form.Group>
                  </Row>
                  <Button
                    variant="danger"
                    onClick={() => removeDocument(index)}
                  >
                    Remove Document
                  </Button>
                </div>
              ))}
            <div className="d-flex justify-content-between align-items-center">
              <Button variant="secondary" onClick={addDocument}>
                Add Document
              </Button>
              <Button variant="primary" type="submit">
                Save and Continue
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </Container>
  );
};

export default EditOfferingDocuments;
