import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image';
import ListGroup from 'react-bootstrap/ListGroup';
import { Button, Modal, Spinner, Form, Table } from 'react-bootstrap';
import axios from 'axios';
import numbro from 'numbro';
import AddAccountModal from './AddAccountModal';
import { formatRelativeTime, formatAccountSubType } from '../../utils';
import { formatOptions } from '../../constants';
import { useAuth } from '../../contexts/AuthContext';
import { NavLink } from 'react-router-dom';

const CreateAccountModal: React.FC<{
  show: boolean;
  handleClose: () => void;
  handleCreate: (account: any) => void;
}> = ({ show, handleClose, handleCreate }) => {
  const [accountName, setAccountName] = useState('');
  const [accountType, setAccountType] = useState('');
  const [accountSubtype, setAccountSubtype] = useState('');
  const accountNameRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (show && accountNameRef.current) {
      accountNameRef.current.focus();
    }
  }, [show]);

  const handleSubmit = () => {
    const newAccount = {
      account_name: accountName,
      account_type: accountType,
      account_subtype: accountSubtype,
    };
    handleCreate(newAccount);
    setAccountName('');
    setAccountType('');
    setAccountSubtype('');
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Create New Ledger Account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="accountName">
            <Form.Label>Account Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter account name"
              value={accountName}
              onChange={(e) => setAccountName(e.target.value)}
              ref={accountNameRef}
            />
          </Form.Group>
          <Form.Group controlId="accountType" className="mt-3">
            <Form.Label>Account Type</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter account type"
              value={accountType}
              onChange={(e) => setAccountType(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="accountSubtype" className="mt-3">
            <Form.Label>Account Subtype</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter account subtype"
              value={accountSubtype}
              onChange={(e) => setAccountSubtype(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Create Account
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const LedgerAccounts: React.FC = () => {
  const { auth } = useAuth();
  const { ledger_id } = useParams<{ ledger_id: string }>();
  const [ledger, setLedger] = useState<any>(null);
  const [accounts, setAccounts] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  async function fetchAccounts() {
    try {
      const { data } = await axios({
        url: `${process.env.REACT_APP_API_URL}/v1/ledgers/${ledger_id}/accounts`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      setAccounts(data.ledger_accounts);
    } catch (error: any) {
      console.log(error);
    }
  }

  const fetchLedgerDetails = async () => {
    try {
      const ledgerResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/ledgers/${ledger_id}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      setLedger(ledgerResponse.data.result);

      const accountsResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/ledgers/${ledger_id}/accounts`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      setAccounts(accountsResponse.data.result);
    } catch (error) {
      console.error('Error fetching ledger details:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateAccount = async (newAccount: Partial<any>) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/ledgers/${ledger_id}/accounts`,
        {
          ...newAccount,
          ledger_id,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      setAccounts([...accounts, response.data.result]);
      setShowModal(false);
    } catch (error) {
      console.error('Error creating ledger account:', error);
    }
  };

  useEffect(() => {
    fetchLedgerDetails();
  }, [ledger_id]);

  if (loading) {
    return <Spinner animation="border" />;
  }

  return (
    <>
      <Helmet>
        <title>Ledger Accounts</title>
      </Helmet>
      <Navbar expand="lg" className="bg-body-tertiary border">
        <Container fluid>
          {/* Breadcrumb */}
          <div className="container-fluid py-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <NavLink to="/ledgers">Ledgers</NavLink>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {ledger_id}
                </li>
              </ol>
            </nav>
          </div>
        </Container>
      </Navbar>
      <Container className="my-3">
        <h1>{ledger?.ledger_name}</h1>
        <p>{ledger?.ledger_description}</p>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Account Name</th>
              <th>Account Type</th>
              <th>
                {ledger.ledger_type === 'Equity'
                  ? 'Shares Issued'
                  : 'Current Balance'}
              </th>
              <th>Created At</th>
              <th>Updated At</th>
            </tr>
          </thead>
          <tbody>
            {accounts?.map((account: any) => (
              <tr key={account.ledger_account_id}>
                <td>
                  <Link
                    to={`/ledgers/${ledger_id}/accounts/${account.ledger_account_id}/transactions`}
                    className="text-decoration-none"
                  >
                    {account.account_name}
                  </Link>
                </td>
                <td>{account.account_type}</td>
                <td>
                  {ledger.ledger_type !== 'Equity' && '$'}
                  {numbro(account.current_balance).format(formatOptions)}
                </td>
                <td>{new Date(account.created_at).toLocaleString()}</td>
                <td>{new Date(account.updated_at).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>

      <AddAccountModal
        auth={auth}
        show={showModal}
        onExit={fetchAccounts}
        handleClose={() => setShowModal(false)}
        ledgerId={ledger_id}
        // handleCreate={handleCreateAccount}
      />
    </>
  );
};

export default LedgerAccounts;
