import React, { useEffect, useState, useRef } from 'react';
import numbro from 'numbro';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Button,
  Container,
  Modal,
  Form,
  Table,
  Spinner,
} from 'react-bootstrap';
import Info from '../../components/Info';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';

interface Ledger {
  ledger_id: string;
  ledger_name: string;
  ledger_description: string;
  created_at: string;
  updated_at: string;
}

interface CreateLedgerProps {
  show: boolean;
  handleClose: () => void;
  handleCreate: (ledger: Partial<Ledger>) => void;
}

const CreateLedgerModal: React.FC<CreateLedgerProps> = ({
  show,
  handleClose,
  handleCreate,
}) => {
  const [ledgerName, setLedgerName] = useState('');
  const [ledgerDescription, setLedgerDescription] = useState('');
  const ledgerNameRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (show && ledgerNameRef.current) {
      ledgerNameRef.current.focus();
    }
  }, [show]);

  const handleSubmit = () => {
    const newLedger = {
      ledger_name: ledgerName,
      ledger_description: ledgerDescription,
    };
    handleCreate(newLedger);
    setLedgerName('');
    setLedgerDescription('');
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Create New Ledger</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="ledgerName">
            <Form.Label>Ledger Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter ledger name"
              value={ledgerName}
              onChange={(e) => setLedgerName(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="ledgerDescription" className="mt-3">
            <Form.Label>Ledger Description</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter ledger description"
              value={ledgerDescription}
              onChange={(e) => setLedgerDescription(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Create Ledger
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

function Ledgers() {
  const { auth } = useAuth();
  const [ledgers, setLedgers] = useState<Ledger[]>([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const fetchLedgers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/ledgers`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      setLedgers(response.data.result);
    } catch (error) {
      console.error('Error fetching ledgers:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateLedger = async (newLedger: Partial<Ledger>) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/ledgers`,
        {
          ledger_name: newLedger.ledger_name,
          ledger_description: newLedger.ledger_description,
          metadata: {}, // Add any necessary metadata here
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      setLedgers([...ledgers, response.data.result]);
      setShowModal(false);
    } catch (error) {
      console.error('Error creating ledger:', error);
    }
  };

  useEffect(() => {
    fetchLedgers();
  }, []);

  if (loading) {
    return <Spinner animation="border" />;
  }

  return (
    <Container>
      <h1 className="my-4">Ledgers</h1>
      {/* <Button
        variant="primary"
        onClick={() => setShowModal(true)}
        className="mb-3"
      >
        Create Ledger
      </Button> */}
      <Table striped bordered hover className="mt-4">
        <thead>
          <tr>
            <th>Ledger Name</th>
            <th>Description</th>
            <th>Created At</th>
            <th>Updated At</th>
          </tr>
        </thead>
        <tbody>
          {ledgers.map((ledger) => (
            <tr key={ledger.ledger_id}>
              <td>
                <Link
                  to={`/ledgers/${ledger.ledger_id}`}
                  className="text-decoration-none"
                >
                  {ledger.ledger_name}
                </Link>
              </td>
              <td>{ledger.ledger_description}</td>
              <td>{new Date(ledger.created_at).toLocaleString()}</td>
              <td>{new Date(ledger.updated_at).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <CreateLedgerModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        handleCreate={handleCreateLedger}
      />
    </Container>
  );
}

export default Ledgers;
