import Subscription from '@capsign/contracts/artifacts/contracts/platform/SubscriptionManager.sol/SubscriptionManager.json';
import Auction from '@capsign/contracts/artifacts/contracts/markets/Auction.sol/Auction.json';
import Identity from '@capsign/contracts/artifacts/contracts/identity/Identity.sol/Identity.json';
import IdentityFactory from '@capsign/contracts/artifacts/contracts/identity/factory/IdFactory.sol/IdFactory.json';
import IdentityRegistry from '@capsign/contracts/artifacts/contracts/securities/registry/implementation/IdentityRegistry.sol/IdentityRegistry.json';
import ImplementationAuthority from '@capsign/contracts/artifacts/contracts/identity/proxy/ImplementationAuthority.sol/ImplementationAuthority.json';
import ModularCompliance from '@capsign/contracts/artifacts/contracts/securities/compliance/modular/ModularCompliance.sol/ModularCompliance.json';
import Token from '@capsign/contracts/artifacts/contracts/securities/token/Token.sol/Token.json';
import TREXFactory from '@capsign/contracts/artifacts/contracts/securities/factory/TREXFactory.sol/TREXFactory.json';
import SAFE from '@capsign/contracts/artifacts/contracts/platform/SAFE.sol/SAFE.json';
import USD from '@capsign/contracts/artifacts/contracts/platform/USD.sol/USD.json';
import RegCF from '@capsign/contracts/artifacts/contracts/offerings/RegCF.sol/RegCF.json';
import { ContractInfo } from './types';
import { ContractInterface, utils } from 'ethers';
import config from '../package.json';
export const version = config.version;

// Load the JSON data
import localhost from './addresses.localhost.json';
import xrpl_devnet from './addresses.xrpl_devnet.json';
import base_mainnet from './addresses.base_mainnet.json';
import base_sepolia from './addresses.base_sepolia.json';
import { Abi } from 'viem';

export const contracts: {
  [key: number]: { [name: string]: ContractInfo };
} = {
  130: {
    IdentityImplementationAuthority: {
      address: '',
      abi: ImplementationAuthority.abi as Abi & ContractInterface,
    },
    Auction: {
      address: '',
      abi: Auction.abi as Abi & ContractInterface,
    },
    Subscription: {
      address: '0x0BE76cc9b7c4de59C89752E3fCB63AB4C2d96CC7',
      abi: Subscription.abi as Abi & ContractInterface,
    },
    USD: {
      address: '0x09CBB7323055E2559CbAAe8D4496e1a7D47e126a',
      abi: Token.abi as Abi & ContractInterface,
    },
    CAP: {
      address: '0xF410B1684B2D015dC688d60ade0a1c10dEeab967',
      abi: Token.abi as Abi & ContractInterface,
    },
    RegCF: {
      address: '',
      abi: RegCF.abi as Abi & ContractInterface,
    },
  },
  8453: {
    Identity: {
      address: base_mainnet.implementations.identityImplementation,
      abi: Identity.abi as Abi & ContractInterface,
    },
    IdentityFactory: {
      address: xrpl_devnet.factories.identityFactory,
      abi: IdentityFactory.abi as Abi & ContractInterface,
    },
    IdentityRegistry: {
      address: base_mainnet.suite.identityRegistry,
      abi: IdentityRegistry.abi as Abi & ContractInterface,
    },
    IdentityImplementationAuthority: {
      address: base_mainnet.authorities.identityImplementationAuthority,
      abi: ImplementationAuthority.abi as Abi & ContractInterface,
    },
    Auction: {
      address: base_mainnet.suite.auction,
      abi: Auction.abi as Abi & ContractInterface,
    },
    ModularCompliance: {
      address: base_mainnet.implementations.modularComplianceImplementation,
      abi: ModularCompliance.abi as Abi & ContractInterface,
    },
    Subscription: {
      address: base_mainnet.suite.subscriptionManager,
      abi: Subscription.abi as Abi & ContractInterface,
    },
    TREXFactory: {
      address: base_mainnet.factories.trexFactory,
      abi: TREXFactory.abi as Abi & ContractInterface,
    },
    Token: {
      address: base_mainnet.implementations.tokenImplementation,
      abi: Token.abi as Abi & ContractInterface,
    },
    SAFE: {
      address: '',
      abi: SAFE.abi as Abi & ContractInterface,
      bytecode: SAFE.bytecode,
    },
    USD: {
      address: base_mainnet.tokens.usd,
      abi: USD.abi as Abi & ContractInterface,
    },
    RegCF: {
      address: '',
      abi: RegCF.abi as Abi & ContractInterface,
    },
  },
  31337: {
    Identity: {
      address: localhost.implementations.identityImplementation,
      abi: Identity.abi as Abi & ContractInterface,
    },
    IdentityFactory: {
      address: xrpl_devnet.factories.identityFactory,
      abi: IdentityFactory.abi as Abi & ContractInterface,
    },
    IdentityRegistry: {
      address: localhost.suite.identityRegistry,
      abi: IdentityRegistry.abi as Abi & ContractInterface,
    },
    IdentityImplementationAuthority: {
      address: localhost.authorities.identityImplementationAuthority,
      abi: ImplementationAuthority.abi as Abi & ContractInterface,
    },
    Auction: {
      address: localhost.suite.auction,
      abi: Auction.abi as Abi & ContractInterface,
    },
    ModularCompliance: {
      address: localhost.implementations.modularComplianceImplementation,
      abi: ModularCompliance.abi as Abi & ContractInterface,
    },
    Subscription: {
      address: localhost.suite.subscriptionManager,
      abi: Subscription.abi as Abi & ContractInterface,
    },
    TREXFactory: {
      address: localhost.factories.trexFactory,
      abi: TREXFactory.abi as Abi & ContractInterface,
    },
    Token: {
      address: localhost.implementations.tokenImplementation,
      abi: Token.abi as Abi & ContractInterface,
    },
    SAFE: {
      address: '',
      abi: SAFE.abi as Abi & ContractInterface,
      bytecode: SAFE.bytecode,
    },
    USD: {
      address: localhost.tokens.usd,
      abi: USD.abi as Abi & ContractInterface,
    },
    RegCF: {
      address: '',
      abi: RegCF.abi as Abi & ContractInterface,
    },
  },
  84532: {
    Identity: {
      address: base_sepolia.implementations.identityImplementation,
      abi: Identity.abi as Abi & ContractInterface,
    },
    IdentityFactory: {
      address: xrpl_devnet.factories.identityFactory,
      abi: IdentityFactory.abi as Abi & ContractInterface,
    },
    IdentityRegistry: {
      address: base_sepolia.suite.identityRegistry,
      abi: IdentityRegistry.abi as Abi & ContractInterface,
    },
    IdentityImplementationAuthority: {
      address: base_sepolia.authorities.identityImplementationAuthority,
      abi: ImplementationAuthority.abi as Abi & ContractInterface,
    },
    Auction: {
      address: base_sepolia.suite.auction,
      abi: Auction.abi as Abi & ContractInterface,
    },
    ModularCompliance: {
      address: base_sepolia.implementations.modularComplianceImplementation,
      abi: ModularCompliance.abi as Abi & ContractInterface,
    },
    Subscription: {
      address: base_sepolia.suite.subscriptionManager,
      abi: Subscription.abi as any,
    },
    TREXFactory: {
      address: base_sepolia.factories.trexFactory,
      abi: TREXFactory.abi as Abi & ContractInterface,
    },
    Token: {
      address: base_sepolia.implementations.tokenImplementation,
      abi: Token.abi as Abi & ContractInterface,
    },
    SAFE: {
      address: '',
      abi: SAFE.abi as Abi & ContractInterface,
      bytecode: SAFE.bytecode,
    },
    USD: {
      address: base_sepolia.tokens.usd,
      abi: USD.abi as Abi & ContractInterface,
    },
    RegCF: {
      address: '',
      abi: RegCF.abi as Abi & ContractInterface,
    },
  },
  1440002: {
    Identity: {
      address: xrpl_devnet.implementations.identityImplementation,
      abi: Identity.abi as Abi & ContractInterface,
    },
    IdentityFactory: {
      address: xrpl_devnet.factories.identityFactory,
      abi: IdentityFactory.abi as Abi & ContractInterface,
    },
    IdentityImplementationAuthority: {
      address: xrpl_devnet.authorities.identityImplementationAuthority,
      abi: ImplementationAuthority.abi as Abi & ContractInterface,
    },
    IdentityRegistry: {
      address: xrpl_devnet.suite.identityRegistry,
      abi: IdentityRegistry.abi as Abi & ContractInterface,
    },
    Auction: {
      address: xrpl_devnet.suite.auction,
      abi: Auction.abi as Abi & ContractInterface,
    },
    ModularCompliance: {
      address: localhost.implementations.modularComplianceImplementation,
      abi: ModularCompliance.abi as Abi & ContractInterface,
    },
    Subscription: {
      address: xrpl_devnet.suite.subscriptionManager,
      abi: Subscription.abi as Abi & ContractInterface,
    },
    TREXFactory: {
      address: xrpl_devnet.factories.trexFactory,
      abi: TREXFactory.abi as Abi & ContractInterface,
    },
    Token: {
      address: xrpl_devnet.implementations.tokenImplementation,
      abi: Token.abi as Abi & ContractInterface,
    },
    SAFE: {
      address: '',
      abi: SAFE.abi as Abi & ContractInterface,
      bytecode: SAFE.bytecode,
    },
    USD: {
      address: xrpl_devnet.tokens.usd,
      abi: USD.abi as Abi & ContractInterface,
    },
    RegCF: {
      address: '',
      abi: RegCF.abi as Abi & ContractInterface,
    },
  },
};

// NumbroJS
export const formatOptions = {
  thousandSeparated: true,
  optionalMantissa: true,
  trimMantissa: false,
  mantissa: 2,
};
