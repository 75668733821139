import React from 'react';
import { createRoot } from 'react-dom/client';
import { WagmiProvider } from 'wagmi';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AuthProvider } from './contexts/AuthContext';
import { ThemeProvider } from './contexts/ThemeContext';
import './index.css';
import App from './containers/App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ErrorProvider } from './contexts/ErrorContext';
import { AccountProvider } from './contexts/AccountContext';
import { MobileProvider } from './contexts/MobileContext';
import { OnboardingProvider } from './contexts/OnboardingContext';
import { EntityProvider } from './contexts/EntityContext';
import { AppDataProvider } from './contexts/AppDataContext';
import { ToastProvider } from './contexts/ToastContext';
import { config } from './config';
import { OfferingProvider } from './contexts/OfferingContext';

const queryClient = new QueryClient();

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  // <React.StrictMode>
  <AuthProvider>
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <AppDataProvider>
          <OnboardingProvider>
            <AccountProvider>
              <OfferingProvider>
                <EntityProvider>
                  <MobileProvider>
                    <ErrorProvider>
                      <ToastProvider>
                        <ThemeProvider>
                          <App />
                        </ThemeProvider>
                      </ToastProvider>
                    </ErrorProvider>
                  </MobileProvider>
                </EntityProvider>
              </OfferingProvider>
            </AccountProvider>
          </OnboardingProvider>
        </AppDataProvider>
      </QueryClientProvider>
    </WagmiProvider>
  </AuthProvider>
  // {/* </React.StrictMode> */}
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
