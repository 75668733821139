import React, { useState, useEffect } from 'react';
import { Contract, Event } from 'ethers';
import { useWeb3React } from '@web3-react/core';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { contracts } from '../../constants';
import { useAuth } from '../../contexts/AuthContext';
import { Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useError } from '../../contexts/ErrorContext';
import { maxBlockRange } from '../../utils';

// Define the type for grouped events
interface GroupedEvents {
  [listingId: string]: Event[];
}

function TransferHistory() {
  const { setError } = useError();
  const { chainId, library } = useWeb3React();
  const { auth } = useAuth();
  const [groupedEvents, setGroupedEvents] = useState<GroupedEvents>({});
  const [loading, setLoading] = useState<Record<string, boolean>>({});
  const [showEventModal, setShowEventModal] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);

  useEffect(() => {
    async function fetchEvents() {
      try {
        if (!chainId || !library) return;

        const contractAddress = contracts[chainId]?.Auction?.address;
        const contractABI = contracts[chainId]?.Auction?.abi;

        if (!contractAddress) return;

        const contract = new Contract(
          contractAddress,
          contractABI,
          library.getSigner()
        );
        const currentBlock = await library.getBlockNumber();
        const fromBlock = Math.max(currentBlock - maxBlockRange[chainId!], 0);

        const fetchedEvents = await Promise.all([
          contract.queryFilter(
            contract.filters.ListingCreated(),
            fromBlock,
            currentBlock
          ),
          contract.queryFilter(
            contract.filters.ListingWithdrawn(),
            fromBlock,
            currentBlock
          ),
          contract.queryFilter(
            contract.filters.ListingPending(),
            fromBlock,
            currentBlock
          ),
          contract.queryFilter(
            contract.filters.ListingSold(),
            fromBlock,
            currentBlock
          ),
        ]);

        const flattenedEvents = fetchedEvents
          .flat()
          .sort((a, b) => b.blockNumber - a.blockNumber);

        // Group events by Listing ID
        const newGroupedEvents = flattenedEvents.reduce<GroupedEvents>(
          (acc: GroupedEvents, event: Event) => {
            const listingId = event.args![0].toString();
            if (!acc[listingId]) {
              acc[listingId] = [];
            }
            acc[listingId].push(event);
            return acc;
          },
          {}
        );

        setGroupedEvents(newGroupedEvents);
      } catch (error) {
        console.log(error);
      }
    }

    try {
      fetchEvents();
    } catch (error) {
      console.log(error);
    }
  }, [chainId, library, auth?.token]);

  const showEventDetails = (event: Event) => {
    setSelectedEvent(event);
    setShowEventModal(true);
  };

  const settle = async (listingId: string, eventArgs: any[]) => {
    setLoading((prev) => ({ ...prev, [listingId]: true }));
    try {
      const args = eventArgs ?? [];
      const [listingId, token, bidId] =
        args.length > 0 ? args : [undefined, undefined];
      const rofr = false; // Right of first refusal flag
      const listingsContract = new Contract(
        contracts[chainId!].Auction.address,
        contracts[chainId!].Auction.abi,
        library.getSigner()
      );
      const params = [listingId, bidId, rofr, { gasLimit: 3_000_000 }];
      console.log(params);
      await listingsContract.callStatic.settle(...params);
      const tx = await listingsContract.settle(...params);
      await tx.wait();
      alert('Settlement successful');
    } catch (error) {
      console.error('Settlement failed:', error);
      setError(error);
    }
    setLoading((prev) => ({ ...prev, [listingId]: false }));
  };

  return (
    <Container className="py-5">
      <h4 className="mb-2">Recent Transfer Events</h4>
      <Table hover responsive>
        {Object.entries(groupedEvents).map(([listingId, events]) => (
          <React.Fragment key={listingId}>
            <thead>
              <tr>
                <th colSpan={5}>Listing ID: {listingId}</th>
              </tr>
              <tr>
                <th>Event Type</th>
                <th>Block Number</th>
                <th>Transaction Hash</th>
                <th>Details</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {events.map((event: Event, index: number) => (
                <tr key={index}>
                  <td>
                    <Badge bg="secondary">{event.event}</Badge>
                  </td>
                  <td>{event.blockNumber}</td>
                  <td>{event.transactionHash}</td>
                  <td>
                    <Button
                      variant="outline-info"
                      size="sm"
                      onClick={() => showEventDetails(event)}
                    >
                      View Details
                    </Button>
                  </td>
                  <td>
                    {event.event === 'ListingPending' &&
                      !events.some((e) => e.event === 'ListingSold') && (
                        <Button
                          variant="primary"
                          size="sm"
                          disabled={loading[listingId]}
                          onClick={() =>
                            settle(listingId, event.args ? [...event.args] : [])
                          }
                        >
                          {loading[listingId] ? (
                            <FontAwesomeIcon icon={faSpinner} spin />
                          ) : (
                            'Settle'
                          )}
                        </Button>
                      )}
                  </td>
                </tr>
              ))}
            </tbody>
          </React.Fragment>
        ))}
        {Object.keys(groupedEvents).length === 0 && (
          <tbody>
            <tr>
              <td colSpan={5} className="text-center py-5">
                No transfer events found.
              </td>
            </tr>
          </tbody>
        )}
      </Table>
      <Modal
        show={showEventModal}
        onHide={() => setShowEventModal(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Event Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <pre>{JSON.stringify(selectedEvent, null, 2)}</pre>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEventModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default TransferHistory;
