import React, { useState } from 'react';
import axios from 'axios';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';

export default function FormationCertificate() {
  const [formationCertificate, setFormationCertificate] = useState<any>(null); // For the formation certificate file

  const { auth } = useAuth();
  const navigate = useNavigate();

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData();
    if (formationCertificate) {
      formData.append('formationCertificate', formationCertificate);
    }

    try {
      const headers = {
        Authorization: `Bearer ${auth?.token}`,
        'Content-Type': 'multipart/form-data',
      };

      await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/entities`,
        formData,
        { headers }
      );
      navigate('/onboarding/create-id?walletType=self-custody');
    } catch (error: any) {
      console.error('Error creating account:', error.response.data);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormationCertificate((event.target as HTMLInputElement).files![0]);
  };

  return (
    <div className="row">
      <Form className="col-lg-4 offset-lg-4 col-md-12 py-5" onSubmit={onSubmit}>
        <Form.Group controlId="formationCertificate" className="mt-3">
          <Form.Label>Formation Certificate (PDF or Word)</Form.Label>
          <Form.Control
            type="file"
            onChange={handleFileChange}
            accept=".pdf,.doc,.docx"
          />
        </Form.Group>

        <Button variant="primary" className="mt-5 w-100" type="submit">
          Continue
        </Button>
      </Form>
    </div>
  );
}
