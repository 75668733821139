import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { NavLink, Outlet } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

export default function Settings() {
  const { auth } = useAuth();
  const navigate = useNavigate();

  // Redirects to the login page if not authenticated
  if (process.env.REACT_APP_REQUIRE_AUTH === 'true' && !auth) {
    navigate('/login');
  }

  return (
    <>
      <Helmet>
        <title>CapSign | Settings</title>
      </Helmet>
      <div className="col-xl-10 offset-xl-1 col-lg-12 col-md-12">
        <div className="settings px-2 mb-4">
          <header className="border-bottom mb-3">
            <ul className="nav nav-tabs nav-tabs-flush gap-6 overflow-x border-0 mt-4">
              <li className="nav-item">
                <NavLink className="nav-link" to="" end>
                  General
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="security" end>
                  Security
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="banking">
                  Banking
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="billing">
                  Billing
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="apps">
                  Apps
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="api" end>
                  API
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink className="nav-link" to="password">
                  Password
                </NavLink>
              </li> */}
              {/* <li className="nav-item">
                <NavLink className="nav-link" to="notifications">
                  Notifications
                </NavLink>
              </li> */}
              {/* <li className="nav-item">
                <NavLink className="nav-link" to="team">
                  Team
                </NavLink>
              </li> */}
            </ul>
          </header>
          <Outlet />
        </div>
      </div>
    </>
  );
}
