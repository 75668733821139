// EditOfferingPaymentTerms.tsx
import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { useOffering } from '../../../contexts/OfferingContext';
import {
  useAccount,
  useChainId,
  usePublicClient,
  useReadContract,
} from 'wagmi';
import { contracts } from '../../../constants';

const EditOfferingPaymentTerms: React.FC = () => {
  const { address: account } = useAccount();
  const chainId = useChainId();
  const publicClient = usePublicClient();
  const { auth } = useAuth();
  const { offeringId } = useParams<{ offeringId: string }>();
  const { offering } = useOffering();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    paymentTokenAddress: '0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0',
    investmentDeadline: '',
    minInvestment: 0,
  });

  const contract = contracts[chainId!].Token;

  const { data: name } = useReadContract({
    address: formData.paymentTokenAddress as `0x${string}`,
    abi: contract.abi,
    functionName: 'name',
  });

  const { data: symbol } = useReadContract({
    address: formData.paymentTokenAddress as `0x${string}`,
    abi: contract.abi,
    functionName: 'symbol',
  });

  const { data: decimals } = useReadContract({
    address: formData.paymentTokenAddress as `0x${string}`,
    abi: contract.abi,
    functionName: 'decimals',
  });

  useEffect(() => {
    const fetchPaymentTerms = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/payment_terms`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        );

        // Convert investmentDeadline to yyyy-MM-dd format
        const investmentDeadline = response.data.result.investment_deadline
          ? new Date(response.data.result.investment_deadline)
              .toISOString()
              .split('T')[0]
          : '';
        setFormData({
          ...formData,
          paymentTokenAddress: response.data.result.payment_token_address,
          investmentDeadline,
          minInvestment: response.data.result.minimum_investment,
        });
      } catch (error) {
        console.error('Error fetching payment terms:', error);
      }
    };

    fetchPaymentTerms();
  }, [offeringId, auth?.token]);

  const handleFormChange = (event: any) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      await axios({
        url: `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/payment_terms`,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
        data: {
          payment_token_address: formData.paymentTokenAddress,
          investment_deadline: formData.investmentDeadline,
          minimum_investment: formData.minInvestment,
        },
      });
      navigate(`/offerings/${offeringId}`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container className="my-5">
      <div className="row">
        <div className="col-4 offset-4">
          <h2>Edit Payment Terms</h2>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridPaymentToken">
                <Form.Label>Payment Token Address</Form.Label>
                <Form.Control
                  type="text"
                  name="paymentTokenAddress"
                  placeholder="Enter payment token address"
                  value={formData.paymentTokenAddress}
                  onChange={handleFormChange}
                  required
                />
              </Form.Group>
            </Row>
            {formData.paymentTokenAddress && (
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridTokenDetails">
                  <Form.Label>Token Details</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Token Symbol"
                    value={symbol}
                    readOnly
                  />
                  <Form.Control
                    type="text"
                    placeholder="Token Name"
                    value={name}
                    readOnly
                  />
                  <Form.Control
                    type="number"
                    placeholder="Token Decimals"
                    value={decimals}
                    readOnly
                  />
                </Form.Group>
              </Row>
            )}
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridMinInvestment">
                <Form.Label>Minimum Investment</Form.Label>
                <Form.Control
                  type="number"
                  name="minInvestment"
                  placeholder="Enter minimum investment"
                  value={formData.minInvestment}
                  onChange={handleFormChange}
                  required
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridInvestmentDeadline">
                <Form.Label>Investment Deadline</Form.Label>
                <Form.Control
                  type="date"
                  name="investmentDeadline"
                  placeholder="Enter investment deadline"
                  value={
                    formData?.investmentDeadline || formData.investmentDeadline
                  }
                  onChange={handleFormChange}
                  required
                />
              </Form.Group>
            </Row>
            <Button variant="primary" type="submit">
              Save and Finish
            </Button>
          </Form>
        </div>
      </div>
    </Container>
  );
};

export default EditOfferingPaymentTerms;
