import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthContext';
import { useAccount } from '../../../contexts/AccountContext';

export default function Name(props: any) {
  const { auth } = useAuth();
  const { account } = useAccount();
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [legalName, setLegalName] = useState<string>('');
  const [line1, setLine1] = useState<string>('');
  const [line2, setLine2] = useState<string>('');
  const [line3, setStreet3] = useState<string>('');
  const [locality, setLocality] = useState<string>('');
  const [sublocality, setSublocality] = useState<string>('');
  const [adminstrativeAreaLevel1, setAdministrativeAreaLevel1] =
    useState<string>('');
  const [adminstrativeAreaLevel2, setAdministrativeAreaLevel2] =
    useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [countryCode, setCountryCode] = useState<string>('');
  const [postalCode, setPostalCode] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    const getUserDetails = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/users/me/details`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data;
    };

    (async () => {
      try {
        const { first_name, last_name, legal_name, address } =
          await getUserDetails();
        setFirstName(first_name);
        setLastName(last_name);
        setLegalName(legal_name);
        setLine1(address?.line1);
        setLine2(address?.line2);
        setLocality(address?.locality);
        setAdministrativeAreaLevel1(address?.administrative_area_level_1);
        setPostalCode(address?.postal_code);
      } catch (error) {
        console.error(error);
      }

      if (account?.account_type === 'Business Entity')
        return navigate('/tokens');
    })();
  }, []);

  const setUserName = async (event: any) => {
    event.preventDefault();
    const userName = {
      first_name: firstName,
      last_name: lastName,
      legal_name: legalName,
    };

    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/users/me/name`,
        userName,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );

      // Check response status or data to confirm successful address creation
      if (response.status === 200) {
        // Assuming 200 OK means success, adjust as needed
        navigate('/onboarding/individual/address');
      }
    } catch (error) {
      console.error('Failed to save user details:', error);
      alert('Failed to save user details. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className="col-lg-4 offset-lg-4 col-md-12 pb-5">
      <h5 className="mb-4">User Name</h5>

      {/* First Name Field */}
      <div className="mb-3">
        <label className="form-label">First Name</label>
        <input
          type="text"
          className="form-control"
          onChange={(event) => setFirstName(event.target.value)}
          defaultValue={firstName}
        />
      </div>

      {/* Last Name Field */}
      <div className="mb-3">
        <label className="form-label">Last Name</label>
        <input
          type="text"
          className="form-control"
          onChange={(event) => setLastName(event.target.value)}
          defaultValue={lastName}
        />
      </div>

      {/* Legal Name Field */}
      <div className="mb-3">
        <label className="form-label">Legal Name</label>
        <input
          type="text"
          className="form-control"
          onChange={(event) => setLegalName(event.target.value)}
          defaultValue={legalName}
        />
        <small className="text-muted">
          Used for platform-generated legal documentation.
        </small>
      </div>

      <div className="row mt-4">
        <div className="col text-end">
          <button className="btn btn-primary" onClick={setUserName}>
            Continue
          </button>
        </div>
      </div>
    </form>
  );
}
