import axios from 'axios';
import React from 'react';
import { NavLink, Outlet, useParams } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import { useAppData } from '../../../contexts/AppDataContext';

const AppOverview = () => {
  const { auth } = useAuth();
  const { id } = useParams();
  const { appData, setAppData } = useAppData();

  React.useEffect(() => {
    (async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/apps/${id}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      setAppData(response.data.result);
    })();
  }, []);

  return (
    <>
      <main id="content">
        {/* Breadcrumb */}
        <div className="border-bottom">
          <div className="container pb-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <NavLink to="/settings/apps">Apps</NavLink>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {appData?.app_name}
                </li>
              </ol>
            </nav>
          </div>
        </div>

        {/* Main Content */}
        <div className="container content-space-t-2 content-space-b-lg-2 mt-3">
          <div className="row">
            <div className="col-md-4 col-lg-3 mb-9 mb-md-0">
              {/* App Info Sidebar */}
              <div className="text-center mb-4">
                <img
                  className="border rounded w-50"
                  src={appData?.app_logo_url}
                  alt="Logo"
                />
              </div>
              <div className="d-grid mb-7">
                <button className="btn btn-primary disabled">
                  Install now
                </button>
              </div>
              <div className="my-4">
                <h5>Categories</h5>
                {/* Example categories */}
                <div className="flex-wrap">
                  {appData?.categories.map(
                    (category: string, index: number) => {
                      return (
                        <button
                          className="btn btn-secondary btn-sm me-1"
                          key={index}
                        >
                          {category}
                        </button>
                      );
                    }
                  )}
                </div>
              </div>
              <div className="my-4">
                <h5>Website</h5>
                <a
                  href={appData?.app_website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {appData?.app_website}
                </a>
              </div>
            </div>

            <div className="col-md-8 col-lg-9">
              <div className="ps-lg-2">
                <h2>{appData?.app_name}</h2>
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <NavLink className="nav-link" to="info" end>
                      App Info
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="reviews" end>
                      Reviews
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="pricing" end>
                      Pricing
                    </NavLink>
                  </li>
                </ul>
                <div className="tab-content mt-3">
                  <div id="app-info" className="tab-pane fade show active">
                    <Outlet />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default AppOverview;
