import React from 'react';
import { Card, ProgressBar, Button, Spinner } from 'react-bootstrap';
import { contracts } from '../../../constants';
import moment from 'moment';
import {
  useReadContract,
  useWriteContract,
  useChainId,
  useWaitForTransactionReceipt,
  useAccount,
  usePublicClient,
} from 'wagmi';
import {
  decimals,
  subscriptionToken,
  subscriptionTokenAddress,
} from '../../../utils';
import { useError } from '../../../contexts/ErrorContext';
import { formatUnits, zeroAddress } from 'viem';

const CardPlanDetails = () => {
  const { address: account } = useAccount();
  const [loading, setLoading] = React.useState(false);
  const chainId = useChainId();
  const { setError } = useError();
  const publicClient = usePublicClient();
  const { writeContractAsync: approve } = useWriteContract();

  const contractAddress = contracts[chainId!]?.Subscription?.address;
  const usdcAbi = [
    { inputs: [], stateMutability: 'nonpayable', type: 'constructor' },
    {
      inputs: [
        { internalType: 'address', name: 'spender', type: 'address' },
        { internalType: 'uint256', name: 'allowance', type: 'uint256' },
        { internalType: 'uint256', name: 'needed', type: 'uint256' },
      ],
      name: 'ERC20InsufficientAllowance',
      type: 'error',
    },
    {
      inputs: [
        { internalType: 'address', name: 'sender', type: 'address' },
        { internalType: 'uint256', name: 'balance', type: 'uint256' },
        { internalType: 'uint256', name: 'needed', type: 'uint256' },
      ],
      name: 'ERC20InsufficientBalance',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'address', name: 'approver', type: 'address' }],
      name: 'ERC20InvalidApprover',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'address', name: 'receiver', type: 'address' }],
      name: 'ERC20InvalidReceiver',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'address', name: 'sender', type: 'address' }],
      name: 'ERC20InvalidSender',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'address', name: 'spender', type: 'address' }],
      name: 'ERC20InvalidSpender',
      type: 'error',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'spender',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'value',
          type: 'uint256',
        },
      ],
      name: 'Approval',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'from',
          type: 'address',
        },
        { indexed: true, internalType: 'address', name: 'to', type: 'address' },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'value',
          type: 'uint256',
        },
      ],
      name: 'Transfer',
      type: 'event',
    },
    {
      inputs: [
        { internalType: 'address', name: 'owner', type: 'address' },
        { internalType: 'address', name: 'spender', type: 'address' },
      ],
      name: 'allowance',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'spender', type: 'address' },
        { internalType: 'uint256', name: 'value', type: 'uint256' },
      ],
      name: 'approve',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'balanceOf',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'decimals',
      outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'mint',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'name',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'owner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'symbol',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'totalSupply',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'value', type: 'uint256' },
      ],
      name: 'transfer',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'from', type: 'address' },
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'value', type: 'uint256' },
      ],
      name: 'transferFrom',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
  ];
  const contractABI = contracts[chainId!]?.Subscription?.abi;

  const { data: subscriptionFee } = useReadContract({
    address: contractAddress as `0x${string}`,
    abi: contractABI,
    functionName: 'subscriptionFee',
  });

  const { data: subscriptionDetails, refetch: refetchSubscriptionDetails } =
    useReadContract({
      address: contractAddress as `0x${string}`,
      abi: contractABI,
      functionName: 'getSubscriptionDetails',
      args: [account],
    });
  const isCurrentlySubscribed = subscriptionDetails
    ? subscriptionDetails[0]
    : null;
  const startTime = subscriptionDetails ? Number(subscriptionDetails[1]) : null;
  const endTime = subscriptionDetails ? Number(subscriptionDetails[2]) : null;

  const { writeContractAsync: subscribe, data: hash } = useWriteContract();
  const { writeContractAsync: cancelSubscription } = useWriteContract();
  const { isLoading: isConfirming, isSuccess: isConfirmed } =
    useWaitForTransactionReceipt({
      hash,
    });

  const checkAndApproveToken = async (
    amount: bigint,
    tokenAddress: string,
    spenderAddress: string
  ) => {
    if (tokenAddress === zeroAddress) return true; // Skip if native ETH

    const currentAllowance: any = await publicClient?.readContract({
      address: subscriptionTokenAddress[chainId!] as `0x${string}`,
      abi: usdcAbi,
      functionName: 'allowance',
      args: [account, spenderAddress],
    });

    if (BigInt(currentAllowance) < amount) {
      const params = {
        account,
        address: subscriptionTokenAddress[chainId!] as `0x${string}`,
        abi: usdcAbi,
        functionName: 'approve',
        args: [spenderAddress, amount],
      };
      const gas = await publicClient?.estimateContractGas(params);
      const approveTx = await approve({ ...params, gas });
      console.log({ approveTx });
    }
    return true;
  };

  /**
   * @function handleSubscribe
   */
  const handleSubscribe = async () => {
    setLoading(true);
    try {
      const token = subscriptionTokenAddress[chainId!];
      const spender = contracts[chainId!]?.Subscription.address;
      const amount = subscriptionFee!;
      const approved = await checkAndApproveToken(amount, token, spender);

      if (!approved) {
        alert('Failed to approve tokens for transaction.');
        return;
      }
      const params: any = {
        address: contractAddress as `0x${string}`,
        abi: contractABI,
        functionName: 'subscribe',
        account,
      };
      if (subscriptionTokenAddress[chainId!] === zeroAddress)
        params.value = subscriptionFee;
      const gas = await publicClient?.estimateContractGas(params);
      const hash = await subscribe({ ...params, gas });
      // const hash = await subscribe({ ...params });
      refetchSubscriptionDetails(); // Refresh subscription details after subscribing
    } catch (error) {
      console.error('Subscription failed', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  /**
   * @function handleCancelSubscription
   */
  const handleCancelSubscription = async () => {
    setLoading(true);
    try {
      const hash = await cancelSubscription({
        address: contractAddress as `0x${string}`,
        abi: contractABI,
        functionName: 'cancelSubscription',
      });
      refetchSubscriptionDetails(); // Refresh subscription details after cancelling
    } catch (error) {
      console.error('Cancel subscription failed', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  /**
   * @function calculateTimeLeft
   * @returns
   */
  const calculateTimeLeft = () => {
    const _endTime = moment.unix(endTime!);
    const now = moment();
    const duration = moment.duration(_endTime.diff(now));
    return `${duration.days()} days ${duration.hours()} hours left`;
  };

  const totalDuration = 30; // Assuming 30 days subscription duration
  const timeLeft = isCurrentlySubscribed
    ? endTime
      ? calculateTimeLeft()
      : isCurrentlySubscribed
        ? 'Calculating...'
        : ''
    : '';
  const progress = startTime
    ? (moment().diff(moment.unix(startTime), 'days') / totalDuration) * 100
    : 0;

  return (
    <Card className="shadow-sm">
      <Card.Body className="py-4">
        <div className="d-flex align-items-center justify-content-between">
          <h6 className="h5 fw-semibold mb-1">Standard Plan</h6>
          <span
            className={`badge ${isCurrentlySubscribed ? 'text-bg-primary' : 'text-bg-secondary'}`}
          >
            {isCurrentlySubscribed ? 'Active' : 'Inactive'}
          </span>
        </div>
        <div className="d-flex flex-wrap align-items-center justify-content-between mb-5">
          <div>
            <p className="text-sm text-muted">
              Create and manage on-chain equity and Regulation CF offerings.
            </p>
          </div>
          <div>
            <div className="d-flex align-items-center">
              <span className="fw-semibold text-2xl mt-1 me-1"></span>
              <span>
                {subscriptionFee &&
                  formatUnits(subscriptionFee, decimals[chainId!])?.toString()}
              </span>
              <span className="text-muted text-lg ms-1">
                {subscriptionToken[chainId!]} / month
              </span>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between mb-1">
          <span className="text-sm fw-semibold">
            Billing cycle: {totalDuration} days
          </span>
          <span className="text-sm fw-semibold">{timeLeft}</span>
        </div>
        <ProgressBar now={progress} className="progress-sm mb-6" />
        <hr />
        <div className="d-flex justify-content-end">
          {isCurrentlySubscribed ? (
            <Button
              variant="none"
              className="text-danger"
              onClick={handleCancelSubscription}
              disabled={loading}
            >
              {loading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span className="visually-hidden">Loading...</span>
                </>
              ) : (
                'Cancel plan'
              )}
            </Button>
          ) : (
            <Button onClick={handleSubscribe} disabled={loading}>
              {loading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span className="visually-hidden">Loading...</span>
                </>
              ) : (
                'Subscribe'
              )}
            </Button>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default CardPlanDetails;
