import { HashLink } from 'react-router-hash-link';
import { NavLink } from 'react-router-dom';
import LandingNav from '../../components/LandingNav';
import Footer from '../../components/Footer';
import ledger from '../../assets/icons/ledger.png';
import tokenization from '../../assets/icons/tokenization.png';
import dsiLogo from './dsi.svg';
import octcLogo from './octc.png';
import promo from './promo.png';
import './Home.css';
import TokenSupply from './TokenSupply';

const MainContent = () => {
  const landingSectionStyle = {
    padding: '50px',
    color: 'white',
    minHeight: '100vh',
  };

  return (
    <>
      {/*
        <div className="p-3 border-0 bg-primary text-light text-center mb-0" style={{ borderRadius: 0 }} role="alert">
          CapSign Inc. is raising seed capital through a 506(c) securities offering&nbsp;<i className="fa-solid fa-tokenization"></i><br/>
          <a className="btn btn-light btn-sm mt-2" href="https://capsign.com" target="_blank" rel="noreferrer">Invest Today</a>
        </div>
      */}
      <div className="landing-background" style={{ minHeight: '100vh' }}>
        <div className="landing-darken" style={{ minHeight: '100vh' }}>
          <div
            className="d-flex align-items-center justify-content-center h-100"
            style={landingSectionStyle}
          >
            <div className="text-center" style={{ marginTop: '-110px' }}>
              <h3 className="text-light display-3 pb-4 pt-5">
                Infrastructure for Capital Markets
              </h3>
              <h2 className="text-light pb-4">
                Better composability, liquidity, & compliance.
              </h2>
              <NavLink
                className="btn btn-primary btn-lg m-2"
                target="_blank"
                to="https://6v1qwdajlxv.typeform.com/to/PrLYKTM9"
              >
                Get Started
              </NavLink>
              <HashLink
                to="#learn-more"
                className="btn btn-secondary btn-lg m-2"
              >
                Learn More
              </HashLink>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5 px-4" id="learn-more">
        <div className="row">
          <div className="col-12 text-center pt-5">
            <h1 className="display-5 text-primary">
              The Tokenization Standard
            </h1>
            <p className="lead mt-4">
              Enterprise-grade blockchain software for cap table management.
            </p>
          </div>
          {/* <TokenSupply /> */}
        </div>

        {/* <div className="offset-md-2 col-md-8 col-xs-10 border rounded">
          <img src={promo} alt="" className="img-fluid" />
        </div> */}

        {/* Ticker row */}
        {/* <div className="pb-md-5 pb-3">
            <Ticker>
              <div className="d-flex flex-row">
                <Company
                  logo={ETH}
                  symbol={'ETH'}
                  price={2494.17}
                  change24h={0.0}
                  website="https://ethereum.org"
                />
                <Company
                  logo={LNQ}
                  symbol={'LNQ'}
                  price={lnqPrice}
                  change24h={lnqChange24h}
                  website="https://linqto.com"
                />
                <Company
                  logo={RIP}
                  symbol={'RIP'}
                  price={rplPrice}
                  change24h={rplChange24h}
                  website="https://ripple.com"
                />
                <Company
                  logo={CAP}
                  symbol={'CAP'}
                  price={0.155}
                  change24h={0.0}
                  website="https://capsign.com"
                />
                <Company
                  logo={XEN}
                  symbol={'XEN'}
                  price={0.15}
                  change24h={0.0}
                  website="https://xenonledger.com"
                />
                <Company
                  logo={XRP}
                  symbol={'XRP'}
                  price={0.58}
                  change24h={0.0}
                  website="https://xrpl.org"
                />
              </div>
            </Ticker>
          </div> */}

        {/* Benefits Row 1 */}
        <div className="row my-5">
          <div className="col-md-6">
            <div className="card mb-3 p-4">
              <div className="row g-0">
                <div className="col-md-8">
                  <div className="card-body d-flex justify-content-between flex-column h-100">
                    <h2 className="card-title text-center text-md-start mb-5">
                      Equity Management Software
                    </h2>
                    {/* Display this image only on small screens */}
                    <img
                      src={tokenization}
                      className="img-fluid d-md-none d-block mb-4"
                      alt="..."
                    />
                    <div>
                      <p className="card-text lead text-justify">
                        Introducing the{' '}
                        <a
                          href="https://github.com/ethereum/ERCs/pull/579"
                          target="_blank"
                        >
                          ERC-7752 Equity Token{' '}
                          {<i className="fa fa-external-link"></i>}
                        </a>
                        , a new open standard for tokenizing corporations on{' '}
                        Ethereum. CapSign has all the tools you need to run a
                        crowdsale and manage stakeholders.
                      </p>
                    </div>
                  </div>
                </div>
                {/* Hide this image on small screens */}
                <div className="col-md-4 d-none d-md-block">
                  <img
                    src={tokenization}
                    className="img-fluid rounded-start"
                    alt="..."
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card mb-3 p-4">
              <div className="row g-0">
                <div className="col-md-8">
                  <div className="card-body d-flex justify-content-between flex-column h-100">
                    <div className="d-flex align-items-center justify-content-between mb-5">
                      <h2 className="card-title text-center text-md-start mb-0">
                        CMX™
                      </h2>
                      <span className="badge rounded-pill text-bg-primary">
                        In Development
                      </span>
                    </div>
                    {/* Display this image only on small screens */}
                    <img
                      src={ledger}
                      className="img-fluid d-md-none d-block mb-4"
                      alt="..."
                    />
                    <div>
                      <p className="card-text lead text-justify">
                        A patent-pending blockchain corporation for capital
                        markets. Our blockchain corporation (
                        <a
                          href="https://www.investopedia.com/tech/what-dao/"
                          target="_blank"
                        >
                          "DAO"
                        </a>
                        ) declares dividends for preferred validator
                        shareholders every month.
                      </p>
                    </div>
                  </div>
                </div>
                {/* Hide this image on small screens */}
                <div className="col-md-4 d-none d-md-block align-items-center justify-content-center h-100 d-flex">
                  <img
                    src={ledger}
                    className="img-fluid rounded-start"
                    alt="..."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Benefits Row 2 */}
        <div className="row my-5">
          <div className="col-md-4">
            <div className="card mb-3 p-4">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <h3 className="card-title mb-5">Managed Markets</h3>
                  <span className="fa-solid fa-right-left fa-2xl text-secondary mt-3" />
                </div>
                <p className="text-secondary lead">
                  Raise capital and manage secondary market liquidity with
                  entitlements for shareholders, transfer agents, and smart
                  contracts.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card mb-3 p-4">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <h3 className="card-title mb-5">Secondaries & SPVs</h3>
                  <span className="fa-solid fa-layer-group fa-2xl text-secondary mt-3" />
                </div>
                <p className="text-secondary lead">
                  Full-service liquidity solutions for early, mid, and
                  late-stage startups provided by CapSign and our trusted
                  partners.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card mb-3 p-4">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <h3 className="card-title mb-5">Developer Platform</h3>
                  <span className="fa-solid fa-terminal fa-2xl text-secondary mt-3" />
                </div>
                <p className="text-secondary lead">
                  Connect to our API for an even more customized solution for
                  your use case, including APIs for all your reporting needs.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Learn More Row */}
        <div className="row my-5">
          <div className="col-12">
            <div className="card">
              <div className="card-body p-4 bg-body-tertiary">
                <div className="row gx-0">
                  <div className="col-12 col-lg-8 text-center text-lg-start">
                    <h3 className="text-primary mb-0">
                      CapSign is building modern infrastructure for private
                      capital markets.
                    </h3>
                    <p className="text-primary mb-0 mt-2 lead">
                      Powered by commercial blockchain technology, our platform
                      is the most secure, transparent, and scalable software
                      solution for equity management.
                    </p>
                  </div>
                  <div className="col-12 col-lg-4 mt-3 mt-lg-0 d-flex justify-content-center justify-content-lg-end align-items-center">
                    <a
                      href="https://docs.capsign.com/about-capsign/overview"
                      className="btn btn-outline-primary btn-lg d-flex align-items-center justify-content-center"
                      style={{ height: '50px' }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 bg-primary py-5">
        <div className="row">
          <div className="col-12 text-center">
            <h3 className="text-light mb-5">
              Proud Member of Leading Industry Associations
            </h3>
            <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
              <div className="mx-5 my-3">
                <a
                  href="https://www.opencaptablecoalition.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={octcLogo}
                    alt="Open Cap Table Coalition Logo"
                    className="w-100 mb-4"
                  />
                </a>
              </div>
              <div className="logo-wrapper mx-5 my-3">
                <a
                  href="https://digitalsecuritiesinitiative.xyz/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={dsiLogo} alt="DSI Logo" className="w-100" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default function Home() {
  return (
    <>
      <LandingNav />
      <MainContent />
      <Footer />
    </>
  );
}
