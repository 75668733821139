import { useEffect, useState } from 'react';
import axios from 'axios';
import CompanyListItem from './CompanyListItem';
import { useAuth } from '../../contexts/AuthContext';
import { useChainId, useAccount } from 'wagmi';
import CertificateListItem from './CertificateListItem';

export default function CompanyList() {
  const { auth } = useAuth();
  const { address: account } = useAccount();
  const chainId = useChainId();
  const [certificates, setCertificates] = useState<any[]>([]);
  const [logos, setLogos] = useState<{ [key: string]: string }>({});

  /**
   * Fetch certificates
   */
  useEffect(() => {
    (async () => {
      try {
        const url = `${process.env.REACT_APP_API_URL}/v1/accounts/me/certificates`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        });
        const result = response.data.result;
        setCertificates(result);

        // Fetch corporate logos for each certificate
        const logoPromises = result.map(async (certificate: any) => {
          const logoResponse = await axios.get(
            `${process.env.REACT_APP_API_URL}/v1/business_entities/${certificate.issuer_id}/logo`,
            {
              headers: {
                Authorization: `Bearer ${auth?.token}`,
              },
              responseType: 'arraybuffer',
            }
          );
          const contentType = logoResponse.headers['content-type'];
          const base64Logo = Buffer.from(logoResponse.data).toString('base64');
          const logoSrc = `data:${contentType};base64,${base64Logo}`;

          return { issuerId: certificate.issuer_id, logoSrc };
        });

        const logosData = await Promise.all(logoPromises);
        const logoMap: { [key: string]: string } = {};
        logosData.forEach(({ issuerId, logoSrc }) => {
          logoMap[issuerId] = logoSrc;
        });

        setLogos(logoMap);
      } catch (error: any) {
        console.error(error);
      }
    })();
  }, [auth?.token, chainId, account]);

  return (
    <section className="pb-4">
      {certificates.map((certificate: any, index: number) => {
        const logoUrl = logos[certificate.issuer_id];
        return (
          <CertificateListItem
            key={index}
            quantity={certificate.quantity}
            issuer={certificate.issuer_short_name}
            shareClass={certificate.share_class_name}
            logoUrl={logoUrl || 'https://placehold.co/50'}
            issueDate={certificate.issue_date}
            costBasis={certificate.cost_basis}
            pricePerShare={certificate.price_per_share}
            label={certificate.label}
            tokenAddress={certificate.token_address}
            txHash={certificate.tx_hash}
            balanceSecurityHash={certificate.balance_security_hash}
          />
        );
      })}
      {certificates.length === 0 && <div>No certificates to display</div>}
    </section>
  );
}
