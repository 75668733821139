import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../contexts/AuthContext';
import { contracts, formatOptions } from '../../constants';
import { useParams, NavLink } from 'react-router-dom';
import { useError } from '../../contexts/ErrorContext';
import numbro from 'numbro';
import { maxBlockRange } from '../../utils';
import {
  useReadContract,
  useWriteContract,
  useWaitForTransactionReceipt,
  useChainId,
  usePublicClient,
} from 'wagmi';
import { Abi, decodeEventLog, zeroAddress } from 'viem';

enum Operation {
  Mint = 1,
  Burn = 2,
}

export default function Identity() {
  const { auth } = useAuth();
  const { setError } = useError();
  const { tokenId, identityId } = useParams();
  const [identity, setIdentity] = useState<any>();
  const [showModal, setShowModal] = useState(false);
  const [shareClass, setShareClass] = useState<any>();
  const [capTable, setCapTable] = useState<any>();
  const [shareClasses, setShareClasses] = useState<any[]>([]);
  const [securityLegend, setSecurityLegend] = useState<string>(
    'THE SECURITIES REPRESENTED BY THIS CERTIFICATE HAVE NOT BEEN REGISTERED UNDER THE SECURITIES ACT OF 1933, AS AMENDED (THE "ACT"), OR ANY STATE SECURITIES LAWS. THESE SECURITIES MAY NOT BE SOLD, TRANSFERRED, PLEDGED, OR OTHERWISE DISPOSED OF IN THE ABSENCE OF AN EFFECTIVE REGISTRATION STATEMENT UNDER THE ACT AND ANY APPLICABLE STATE SECURITIES LAWS OR AN OPINION OF COUNSEL SATISFACTORY TO THE COMPANY THAT SUCH REGISTRATION IS NOT REQUIRED.'
  );
  const [federalExemption, setFederalExemption] = useState<string>('');
  const [securityId, setSecurityId] = useState('');
  const [operation, setOperation] = useState(Operation.Mint);
  const [tokenAmount, setTokenAmount] = useState('');
  const [pricePerShare, setPricePerShare] = useState('');
  const [mints, setMints] = useState<any[]>([]);
  const [burns, setBurns] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const chainId = useChainId();
  const publicClient = usePublicClient();

  const tokenContract = contracts[chainId!]?.Token;
  const identityRegistryContract = contracts[chainId!]?.IdentityRegistry;

  const { data: balance } = useReadContract({
    address: tokenId as `0x${string}`,
    abi: tokenContract.abi as Abi,
    functionName: 'balanceOf',
    args: [identity?.wallet_address],
  });

  const { data: identityRegistryAddress } = useReadContract({
    address: tokenId as `0x${string}`,
    abi: tokenContract.abi as Abi,
    functionName: 'identityRegistry',
  });

  const { data: isVerified } = useReadContract({
    address: identityRegistryAddress as `0x${string}`,
    abi: identityRegistryContract.abi as Abi,
    functionName: 'isVerified',
    args: [identity?.wallet_address],
  });

  const { writeContractAsync: mintTokens } = useWriteContract();
  const { writeContractAsync: burnTokens } = useWriteContract();
  const { data: mintReceipt, isLoading: isMintLoading } =
    useWaitForTransactionReceipt();
  const { data: burnReceipt, isLoading: isBurnLoading } =
    useWaitForTransactionReceipt();

  /**
   * Fetch share classes
   */
  useEffect(() => {
    (async () => {
      try {
        const response = await axios({
          url: `${process.env.REACT_APP_API_URL}/v1/business_entities/me/equity_token?chain_id=${chainId}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        });
        setShareClasses(response.data.result.share_classes);
        setShareClass(response.data.result.share_classes[0]);
        setCapTable(response.data.result.cap_table);
      } catch (error) {
        setError(error);
      }
    })();
  }, [auth?.token, chainId]);

  /**
   * Fetch security transactions
   */
  useEffect(() => {
    (async () => {
      try {
        if (identity?.wallet_address) {
          const tokenAddress = tokenId as `0x${string}`;

          // Get Transfer event ABI
          const transferEventAbiItem: any = tokenContract.abi.find(
            (item: any) => item.name === 'Transfer'
          )!;

          const latestBlock = await publicClient?.getBlockNumber();
          let fromBlock;
          if (process.env.NODE_ENV === 'development') {
            fromBlock = BigInt(0);
          } else {
            fromBlock = latestBlock! - BigInt(maxBlockRange[chainId!]);
          }

          const mintEvents: any = await publicClient?.getLogs({
            address: tokenAddress,
            event: transferEventAbiItem,
            args: {
              from: zeroAddress as `0x${string}`,
              to: identity?.wallet_address as `0x${string}`,
            },
            fromBlock,
            toBlock: latestBlock,
          });
          setMints(mintEvents);

          const burnEvents: any = await publicClient?.getLogs({
            address: tokenAddress,
            event: transferEventAbiItem,
            args: {
              from: identity?.wallet_address,
              to: zeroAddress,
            },
            fromBlock,
            toBlock: latestBlock,
          });
          setBurns(burnEvents);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [
    identity?.wallet_address,
    tokenAmount,
    chainId,
    publicClient,
    tokenContract.abi,
  ]);

  /**
   * Fetch identities
   */
  useEffect(() => {
    (async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${process.env.REACT_APP_API_URL}/v1/identities/${identityId}`,
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        });
        setIdentity(response.data.result);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [auth?.token, identityId]);

  /**
   * @function handleOperation
   * @param event
   */
  const handleOperation = async (event: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    if (operation === Operation.Mint) await mint();
    if (operation === Operation.Burn) await burn();
  };

  /**
   * @function mint
   * @returns
   */
  const mint = async () => {
    setLoading(true);
    try {
      if (!isVerified) {
        alert('Please register your identity with this token.');
        setLoading(false);
        return;
      }

      const uri = 'https://api.capsign.com/v1/metadata/test.json';
      const params = [
        identity.wallet_address,
        shareClass.class_hex,
        tokenAmount,
        uri,
      ];

      const txHash = await mintTokens({
        args: params,
        address: tokenId as `0x${string}`,
        abi: tokenContract.abi as Abi,
        functionName: 'mint',
        gas: BigInt(3_000_000),
      });
      console.log({ txHash });

      const mintReceipt = await publicClient?.waitForTransactionReceipt({
        hash: txHash,
      });

      console.log({ mintReceipt });

      // Get Transfer event arg here, save it in database below
      const transferEventAbiItem: any = tokenContract.abi.find(
        (item: any) => item.name === 'Transfer'
      )!;

      console.log({ transferEventAbiItem });

      const log = mintReceipt?.logs[0];

      console.log({ log });

      // Extract allowed contract addresses from events and update state
      let balanceSecurityId = null;
      if (log) {
        // Define the event ABI
        const eventAbi = {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'from',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'bytes32',
              name: 'securityId',
              type: 'bytes32',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'bytes32[]',
              name: 'newIds',
              type: 'bytes32[]',
            },
          ],
          name: 'Transfer',
          type: 'event',
        };
        const decodedLog = decodeEventLog({
          abi: [eventAbi],
          data: log.data,
          topics: log.topics,
        });
        balanceSecurityId = (decodedLog?.args! as any).securityId;
        console.log({ balanceSecurityId });
        setSecurityId(balanceSecurityId);
      }

      if (!isMintLoading) {
        // Create certificate
        await axios.post(
          `${process.env.REACT_APP_API_URL}/v1/cap_tables/${capTable.cap_table_id}/certificates`,
          {
            share_class: shareClass.class_id,
            quantity: tokenAmount,
            federal_exemption: federalExemption,
            legend: securityLegend,
            account_id: identity.account_id,
            tx_hash: txHash,
            balance_security_hash: balanceSecurityId,
            price_per_share: pricePerShare,
          },
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        );

        setTokenAmount('');
        setShowModal(false);
        setLoading(false);
        alert(`Transaction success! Tx hash: ${mintReceipt}`);
      }
    } catch (e) {
      setError(e);
      console.error(e);
      setLoading(false);
    }
  };

  /**
   * @function burn
   * @returns
   */
  const burn = async () => {
    setLoading(true);
    try {
      if (!isVerified) {
        alert('Please register your identity with this token.');
        setLoading(false);
        return;
      }

      const params = [identity.wallet_address, securityId, tokenAmount];

      const burnReceipt = await burnTokens({
        address: tokenId as `0x${string}`,
        abi: tokenContract.abi as Abi,
        args: params,
        functionName: 'burn',
      });

      if (!isBurnLoading) {
        setTokenAmount('');
        setShowModal(false);
        setLoading(false);
        alert(`Transaction success! Tx hash: ${burnReceipt}`);
      }
    } catch (e) {
      setError(e);
      console.error(e);
      setLoading(false);
    }
  };

  return (
    <>
      {/* Breadcrumb */}
      <div className="border-bottom py-3 mb-4">
        <div className="container-fluid">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <NavLink to="/equity">Equity</NavLink>
              </li>
              <li className="breadcrumb-item">
                <NavLink to={`/equity/${tokenId}`}>{tokenId}</NavLink>
              </li>
              <li className="breadcrumb-item">
                <NavLink to={`/equity/${tokenId}/identities`}>
                  Entitlements
                </NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {identity?.identity_address}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <Row className="py-5">
        <Col md={{ span: 8, offset: 2 }}>
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h4>User Identity</h4>
            <div>
              Balance: {numbro(balance?.toString()).format(formatOptions)}
            </div>
          </div>
          <div className="card mb-2">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <div>{identity?.wallet_address}</div>
                <Button onClick={() => setShowModal(true)} disabled={loading}>
                  {loading ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    'Manage'
                  )}
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 8, offset: 2 }}>
          <h5>Mint History</h5>
          {mints.map((event: any) => {
            return (
              <div
                key={event.transactionHash}
                className="d-flex justify-content-between align-items-center border-bottom py-3"
              >
                <div>
                  <span className="text-muted">Security ID</span>
                  <br />
                  {event?.args?.securityId.toString()}
                </div>
                <div>
                  <span className="text-muted">Block</span>
                  <br />
                  {event.blockNumber.toString()}
                </div>
                <div>
                  <span className="text-muted">Quantity</span>
                  <br />
                  {numbro(event?.args?.amount.toString()).format(formatOptions)}
                </div>
              </div>
            );
          })}
          {mints.length === 0 && 'No transfer history.'}
        </Col>
      </Row>
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Manage Tokens</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleOperation}>
            <Form.Group className="mb-3">
              <Form.Label>Operation</Form.Label>
              <div>
                <Form.Check
                  type="radio"
                  label="Mint"
                  name="operation"
                  value={Operation.Mint}
                  defaultChecked
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setOperation(Number(e.target.value))
                  }
                  required
                />
                <Form.Check
                  type="radio"
                  label="Burn"
                  name="operation"
                  value={Operation.Burn}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setOperation(Number(e.target.value))
                  }
                  required
                />
              </div>
            </Form.Group>
            {operation === Operation.Mint && (
              <>
                <Form.Group className="mb-3">
                  <Form.Label>Share Class</Form.Label>
                  <Form.Select
                    onChange={(e: any) =>
                      setShareClass(shareClasses[e.target.value])
                    }
                    required
                  >
                    {shareClasses?.map((_class: any, index: number) => {
                      return (
                        <option key={index} value={index}>
                          {`${_class.class_type} ${_class.class_name}`}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Federal Exemption</Form.Label>
                  <Form.Select
                    name="federalExemption"
                    value={federalExemption}
                    onChange={(e) => setFederalExemption(e.target.value)}
                  >
                    <option value="Rule 701">Rule 701</option>
                    <option value="Section 4(a)(2)">Section 4(a)(2)</option>
                    <option value="Section 4(A)(1-1/2)">
                      Section 4(A)(1-1/2)
                    </option>
                    <option value="Section 4(a)(7)">Section 4(a)(7)</option>
                    <option value="Rule 144">Rule 144</option>
                    <option value="Reg D - 506(b)">Reg D - 506(b)</option>
                    <option value="Reg D - 506(c)">Reg D - 506(c)</option>
                    <option value="Reg D - 506">Reg D - 506</option>
                    <option value="Reg D - 505">Reg D - 505</option>
                    <option value="Reg D - 504">Reg D - 504</option>
                    <option value="Reg S">Reg S</option>
                    <option value="Reg A (tier 1)">Reg A (tier 1)</option>
                    <option value="Reg A (tier 2)">Reg A (tier 2)</option>
                    <option value="Reg CF">Reg CF</option>
                    <option value="Non-U.S.">Non-U.S.</option>
                    <option value="Other">Other</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Security Legend</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={11}
                    placeholder="Enter the security legend"
                    name="securityLegend"
                    value={securityLegend}
                    onChange={(e) => setSecurityLegend(e.target.value)}
                  />
                </Form.Group>
              </>
            )}
            <Form.Group className="mb-3">
              <Form.Label>Security ID</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter security ID"
                onChange={(e) => setSecurityId(e.target.value)}
                required={operation === Operation.Burn}
              />
              <small className="text-muted">
                {operation === Operation.Mint ? (
                  <>
                    (Optional) An associated security ID given by an external
                    system.
                  </>
                ) : (
                  <>The on-chain security ID</>
                )}
              </small>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Quantity</Form.Label>
              <Form.Control
                type="number"
                value={tokenAmount}
                onChange={(e) => setTokenAmount(e.target.value)}
                placeholder="Enter amount of tokens"
                required
                disabled={loading}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Price Per Share</Form.Label>
              <Form.Control
                type="number"
                value={pricePerShare}
                onChange={(e) => setPricePerShare(e.target.value)}
                placeholder="Enter price per share"
                required
                disabled={loading}
              />
            </Form.Group>
            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Confirm'}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
