import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button, Spinner, ListGroup, Form } from 'react-bootstrap';
import axios from 'axios';
import { usePlaidLink } from 'react-plaid-link';

const AddAccountModal = ({
  show,
  handleClose,
  auth,
  onExit,
  ledgerId,
}: any) => {
  const [token, setToken] = useState<string | null>(null);
  const [isSyncing, setIsSyncing] = useState(false);
  const [accounts, setAccounts] = useState<any>([]);
  const [selectedAccount, setSelectedAccount] = useState(null);

  const { open, ready } = usePlaidLink({
    token,
    onSuccess: async (public_token, metadata) => {
      try {
        const response = await axios({
          url: 'http://localhost:8080/v1/plaid/exchange_public_token',
          method: 'POST',
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
          data: {
            publicToken: public_token,
            metadata,
          },
        });
        setAccounts(response.data.accounts);
      } catch (error) {
        console.log(error);
      }
    },
    onExit: onExit,
  });

  useEffect(() => {
    if (ready && token) open();
  }, [ready, token]);

  async function fetchLinkToken() {
    try {
      const { data } = await axios({
        url: 'http://localhost:8080/v1/plaid/create_link_token',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
      setToken(data.link_token);
    } catch (error) {
      console.log(error);
    }
  }

  async function syncTransactions() {
    setIsSyncing(true);
    try {
      await axios({
        url: 'http://localhost:8080/v1/plaid/sync_transactions',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
      setIsSyncing(false);
      handleClose();
    } catch (error) {
      console.log(error);
      setIsSyncing(false);
    }
  }

  async function addSelectedAccount() {
    if (selectedAccount) {
      try {
        setIsSyncing(true);
        await axios.post(
          `${process.env.REACT_APP_API_URL}/v1/ledgers/${ledgerId}/internal_accounts`,
          { account: selectedAccount },
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );
        await syncTransactions();
      } catch (error) {
        console.log(error);
        setIsSyncing(false);
      }
    }
  }

  async function onClick(event: React.MouseEvent) {
    event.preventDefault();
    await fetchLinkToken();
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isSyncing && <Spinner animation="border" />}
        {!isSyncing && !token && (
          <>
            <p>
              Select how you would like to add your bank or credit card account:
            </p>
            <div className="d-flex justify-content-around mt-4">
              <Button variant="primary" onClick={onClick}>
                Connect with Plaid
              </Button>
              <Link to="new_account" className="btn btn-secondary">
                Add Manually
              </Link>
            </div>
          </>
        )}
        {!isSyncing && token && accounts.length === 0 && (
          <p>Loading accounts...</p>
        )}
        {!isSyncing && accounts.length > 0 && (
          <Form>
            <Form.Group>
              <Form.Label>Select an account to add:</Form.Label>
              <ListGroup>
                {accounts.map((account: any) => (
                  <ListGroup.Item key={account.account_id}>
                    <Form.Check
                      type="radio"
                      name="account"
                      label={`${account.name} - ${account.mask}`}
                      value={account.id}
                      onChange={() => setSelectedAccount(account)}
                    />
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Form.Group>
            <Button onClick={addSelectedAccount} disabled={!selectedAccount}>
              Add Selected Account
            </Button>
          </Form>
        )}
      </Modal.Body>

      {/* Syncing Modal */}
      <Modal show={isSyncing} centered>
        <Modal.Body className="text-center">
          <Spinner animation="border" />
          <p>Syncing transactions, please wait...</p>
        </Modal.Body>
      </Modal>
    </Modal>
  );
};

export default AddAccountModal;
