import React, { useEffect, useState, useLayoutEffect } from 'react';
import { Outlet, useParams, useLocation } from 'react-router-dom';
import {
  Card,
  Badge,
  Container,
  Row,
  Col,
  ProgressBar,
  Nav,
  Button,
} from 'react-bootstrap';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthContext';
import { NavLink } from 'react-router-dom';
import numbro from 'numbro';
import { formatOptions } from '../../../constants';
import { Link } from 'react-router-dom';
import InvestModal from './InvestModal';
import IndicationModal from './IndicationModal';
import { Helmet } from 'react-helmet';
import './index.css';
import { useToast } from '../../../contexts/ToastContext';

export default function ViewOffering() {
  const { offeringId } = useParams<{ offeringId: string }>();
  const { auth } = useAuth();
  const [offering, setOffering] = useState<any>(null);
  const location = useLocation();
  const [showInvestModal, setShowInvestModal] = useState<boolean>(false);
  const [showInterestModal, setShowInterestModal] = useState<boolean>(false);
  const { setMessage } = useToast();

  const handleShowInterestModal = () => setShowInterestModal(true);
  const handleCloseInterestModal = () => setShowInterestModal(false);

  const handleShowInvestModal = () => setShowInvestModal(true);
  const handleCloseInvestModal = () => setShowInvestModal(false);

  /**
   * Fetch the offering
   */
  useEffect(() => {
    const fetchOffering = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        );
        setOffering(response.data.result);
      } catch (error) {
        console.error('Error fetching offering:', error);
      }
    };

    fetchOffering();
  }, [offeringId, auth]);

  const renderBadge = (status: any) => {
    switch (status) {
      case 'Active':
        return <Badge bg="success">Active</Badge>;
      case 'Completed':
        return <Badge bg="primary">Completed</Badge>;
      case 'Cancelled':
        return <Badge bg="danger">Cancelled</Badge>;
      case 'Draft':
        return <Badge bg="secondary">Draft</Badge>;
      case 'Pending Approval':
        return <Badge bg="warning">Pending Approval</Badge>;
      case 'Paused':
        return <Badge bg="warning">Paused</Badge>;
      case 'Failed':
        return <Badge bg="danger">Failed</Badge>;
      case 'Closed':
        return <Badge bg="secondary">Closed</Badge>;
      case 'Withdrawn':
        return <Badge bg="dark">Withdrawn</Badge>;
      case 'Under Review':
        return <Badge bg="info">Under Review</Badge>;
      case 'Expired':
        return <Badge bg="dark">Expired</Badge>;
      default:
        return <Badge bg="secondary">Unknown</Badge>;
    }
  };

  if (!offering) {
    return <Container>Loading...</Container>;
  }

  const investmentProgress =
    ((offering.offering_status === 'Active'
      ? offering.total_investment
      : offering.indicated_amount) /
      offering.soft_cap) *
    100;

  // Convert investmentDeadline to yyyy-MM-dd format
  const investmentDeadline = offering?.payment_terms?.investment_deadline
    ? new Date(offering?.payment_terms?.investment_deadline)
        .toISOString()
        .split('T')[0]
    : '';

  // Function to refetch offering data after investment
  const handleInvestmentSuccess = async (investmentAmount: number) => {
    try {
      // Refetch offering data
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      setOffering(response.data.result); // Update offering state with new data
      alert('Success');
    } catch (error) {
      console.error('Error fetching updated offering:', error);
    }
  };

  // Function to refetch offering data after interest indication
  const handleIndicationSuccess = async (investmentAmount: number) => {
    try {
      // Refetch offering data
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      setOffering(response.data.result); // Update offering state with new data
      alert('Success');
    } catch (error) {
      console.error('Error fetching updated offering:', error);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(`${window.location.href}`);
    setMessage('Shareable link copied to clipboard!');
  };

  return (
    <>
      <Helmet>
        <title>CapSign | Offerings</title>
      </Helmet>
      {/* Breadcrumb */}
      <div className="border-bottom">
        <div className="container-fluid py-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <NavLink to="/offerings">Offerings</NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {offeringId}
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <Container className="my-5 px-5">
        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <Card>
              {/* <Card.Img variant="top" src={promo} /> */}
              <Card.Body className="p-5">
                <Row>
                  <div className="d-flex align-items-start mb-2">
                    <h2 className="me-2 mb-0">{offering.offering_name}</h2>
                    <div className="align-self-center">
                      {renderBadge(offering.offering_status)}
                    </div>
                  </div>
                </Row>
                <Row>
                  <Col md={6}>
                    <Card.Text>{offering.offering_description}</Card.Text>
                  </Col>
                  <Col md={2}></Col>
                  <Col md={2} className="fixed-top-right">
                    <Card>
                      <Card.Body className="m-1">
                        <div className="row">
                          <div className="col-6">
                            <h4>
                              {numbro(
                                offering.offering_status === 'Active'
                                  ? offering.total_investment
                                  : offering.indicated_amount
                              ).formatCurrency({
                                average: true,
                                mantissa: 2,
                                optionalMantissa: true,
                                currencyPosition: 'prefix',
                              })}
                            </h4>
                            <p>
                              {offering.offering_status === 'Active'
                                ? 'Committed'
                                : 'Indicated'}
                            </p>
                          </div>
                          <div className="col-6 d-flex justify-content-center align-items-center mb-3">
                            <Button variant="primary" onClick={copyToClipboard}>
                              Share <i className="fa-solid fa-link"></i>
                            </Button>
                          </div>
                        </div>
                        <div className="progress mt-4" role="progressbar">
                          <div
                            className={`progress-bar ${investmentProgress === 100 ? 'bg-success' : 'bg-primary progress-bar-striped'}`}
                            style={{ width: `${investmentProgress}%` }}
                          >
                            {investmentProgress}%
                          </div>
                        </div>
                        <p>
                          {offering.offering_status === 'Active'
                            ? 'committed'
                            : 'indicated'}{' '}
                          of {numbro(offering.soft_cap).formatCurrency()} goal
                        </p>
                        <button
                          className={`btn ${offering.offering_status === 'Pending Approval' ? 'btn-outline-primary' : 'btn-primary'} w-100 mt-1`}
                          onClick={handleShowInvestModal}
                          disabled={offering.offering_status !== 'Active'}
                        >
                          Invest
                        </button>
                        <button
                          className={`btn ${offering.offering_status === 'Pending Approval' ? 'btn-primary' : 'btn-outline-primary'} w-100 mt-1`}
                          onClick={handleShowInterestModal}
                          disabled={
                            offering.offering_status !== 'Pending Approval'
                          }
                        >
                          Indicate Interest
                        </button>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                <Row className="my-4">
                  <Col md={6}>
                    <p>
                      <strong>Target Amount:</strong> $
                      {numbro(offering.soft_cap).format(formatOptions)}
                    </p>
                    <p>
                      <strong>Investment Contract:</strong>{' '}
                      {offering.contract_address}
                    </p>
                    <p>
                      <strong>Payment Token (USD):</strong>{' '}
                      {offering?.payment_terms?.payment_token_address}
                    </p>
                  </Col>
                  <Col md={6}>
                    <p>
                      <strong>Amount Raised:</strong> $
                      {numbro(
                        offering.total_investment?.toLocaleString()
                      ).format({ thousandSeparated: true })}
                    </p>
                    <p>
                      <strong>Minimum Investment:</strong> $
                      {offering?.payment_terms?.minimum_investment?.toLocaleString()}
                    </p>
                    <p>
                      <strong>Federal Exemption:</strong>{' '}
                      {offering.federal_exemption}
                    </p>
                    <p>
                      <strong>Investment Deadline:</strong> {investmentDeadline}
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Nav variant="pills" fill defaultActiveKey="details">
                      <Nav.Item>
                        <Nav.Link
                          as={Link}
                          to="details"
                          className={
                            location.pathname.endsWith('/details')
                              ? 'active'
                              : ''
                          }
                        >
                          Details
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          as={Link}
                          to="financials"
                          className={
                            location.pathname.endsWith('/financials')
                              ? 'active'
                              : ''
                          }
                        >
                          Financials
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          as={Link}
                          to="documents"
                          className={
                            location.pathname.endsWith('/documents')
                              ? 'active'
                              : ''
                          }
                        >
                          Documents
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          as={Link}
                          to="video"
                          className={
                            location.pathname.endsWith('/video') ? 'active' : ''
                          }
                        >
                          Video
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          as={Link}
                          to="press"
                          className={
                            location.pathname.endsWith('/press') ? 'active' : ''
                          }
                        >
                          Press
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          as={Link}
                          to="faq"
                          className={
                            location.pathname.endsWith('/faq') ? 'active' : ''
                          }
                        >
                          FAQ
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          as={Link}
                          to="updates"
                          className={
                            location.pathname.endsWith('/updates')
                              ? 'active'
                              : ''
                          }
                        >
                          Updates
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <hr />
                  </Col>
                </Row>

                <Row>
                  <Col
                    sm={12}
                    md={{ span: 10, offset: 1 }}
                    className="d-flex justify-content-center mt-3"
                  >
                    <div className="w-100">
                      <Outlet />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="d-flex justify-content-between align-items-center">
                <div className="text-muted">
                  Created at:{' '}
                  {new Date(offering.created_at).toLocaleDateString()}
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
      <InvestModal
        show={showInvestModal}
        handleClose={handleCloseInvestModal}
        contractAddress={offering?.contract_address}
        offeringId={offering?.offering_id}
        onInvestmentSuccess={handleInvestmentSuccess}
      />
      <IndicationModal
        show={showInterestModal}
        handleClose={handleCloseInterestModal}
        contractAddress={offering?.contract_address}
        offeringId={offering?.offering_id}
        onIndicationSuccess={handleIndicationSuccess}
      />
    </>
  );
}
