import { useEffect, useState } from 'react';
import { Badge, ListGroup } from 'react-bootstrap';
import { contracts } from '../../constants';
import { Contract, Event, utils } from 'ethers';
import { useWeb3React } from '@web3-react/core';
import { useError } from '../../contexts/ErrorContext';
import { maxBlockRange } from '../../utils';

type Bid = {
  id: number;
  listingId: number;
  token: string;
  price: string;
  amount: number;
  bidder: string;
  expiresAt: number;
};

export default function Bids() {
  const { account, chainId, library } = useWeb3React();
  const [bids, setBids] = useState<any[]>([]);
  const { setError } = useError();

  /**
   * Fetch user bids with full details
   */
  useEffect(() => {
    if (!library || !account) return;

    const contract = new Contract(
      contracts[chainId!].Auction.address,
      contracts[chainId!].Auction.abi,
      library
    );

    // Fetching bids directly for the current user using the BidPlaced event filtered by the user's address
    const fetchBids = async () => {
      const filter = contract.filters.BidPlaced(null, null, account);
      const latestBlock = await library.getBlockNumber();
      const fromBlock = latestBlock - maxBlockRange[chainId!];
      const toBlock = latestBlock;
      const events = await contract.queryFilter(filter, fromBlock, toBlock);
      const bidsData = await Promise.all(
        events.map(async (event: Event) => {
          const bidDetails = await contract.bids(
            event.args!.listingId,
            event.args!.bidId
          );
          return {
            id: bidDetails.id.toNumber(),
            listingId: event.args!.listingId.toNumber(),
            price: bidDetails.price.toString(),
            quantity: bidDetails.quantity.toNumber(),
            bidder: bidDetails.bidder,
            status: bidDetails.status,
            createdAt: bidDetails.createdAt,
            expiresAt: bidDetails.expiresAt,
          };
        })
      );
      setBids(bidsData);
    };

    try {
      fetchBids();
    } catch (error) {
      setError(error);
      console.error(error);
    }
  }, [account, library]);

  const cancelBid = async (bid: Bid) => {
    if (!library || !account) {
      console.error('Library or account not available.');
      return;
    }

    const contract = new Contract(
      contracts[chainId!].Auction.address,
      contracts[chainId!].Auction.abi,
      library.getSigner()
    );
    try {
      const tx = await contract.cancelBid(bid.listingId, bid.id);
      await tx.wait();
      console.log(
        `Bid ${bid.id} on listing ${bid.listingId} was cancelled successfully.`
      );
      // Optionally remove the bid from the state or fetch updated bids list
      setBids(bids.filter((b) => b.id !== bid.id));
    } catch (error) {
      console.error('Error cancelling bid:', error);
    }
  };

  const statusEnumToText = (value: number) => {
    return {
      0: 'ACTIVE',
      1: 'WITHDRAWN',
      2: 'EXPIRED',
      3: 'PENDING',
      4: 'SOLD',
    }[value];
  };

  return (
    <>
      <h5>Your Bids</h5>
      {bids?.length === 0 ? (
        'No bids to display'
      ) : (
        <ListGroup variant="flush" className="border w-100">
          {bids.map((bid, index) => {
            console.log(bid);
            return (
              <ListGroup.Item key={index} className="p-3">
                <div className="d-flex justify-content-between">
                  <div>
                    <h6 className="mb-2">
                      Bid on Listing #{bid.listingId.toString()}
                    </h6>
                    <p className="mb-1">
                      <strong>Token:</strong> {bid.baseToken}
                    </p>
                    <p className="mb-1">
                      <strong>Price:</strong> ${utils.formatUnits(bid.price, 2)}
                      /sh
                    </p>
                    <p className="mb-1">
                      <strong>Quantity:</strong> {bid.quantity}
                    </p>
                    <p className="mb-1">
                      <strong>Expires:</strong>{' '}
                      {new Date(
                        bid.expiresAt.toNumber() * 1000
                      ).toLocaleString()}
                    </p>
                  </div>
                  <div className="text-end">
                    <Badge
                      pill
                      bg={bid.status === 'ACTIVE' ? 'success' : 'secondary'}
                      className="mb-2"
                    >
                      {statusEnumToText(bid.status)}
                    </Badge>
                    {/* You can include any buttons or actions here, aligning them to the right */}
                  </div>
                </div>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      )}
    </>
  );
}
