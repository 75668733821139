import React from 'react';
import numbro from 'numbro';
import { formatOptions } from '../../constants';
import { Link } from 'react-router-dom';
import { explorerBaseUrl } from '../../utils';
import { useChainId } from 'wagmi';

interface CertificateListItemProps {
  quantity: number;
  issuer: string;
  shareClass: string;
  logoUrl: string;
  issueDate: string;
  costBasis: string;
  pricePerShare: string;
  label: string;
  tokenAddress: string;
  txHash: string;
  balanceSecurityHash: string;
}

export default function CertificateListItem({
  quantity,
  issuer,
  shareClass,
  logoUrl,
  issueDate,
  costBasis,
  pricePerShare,
  label,
  tokenAddress,
  txHash,
  balanceSecurityHash,
}: CertificateListItemProps) {
  const chainId = useChainId();
  function parseAmountWithCurrency(value: string) {
    const match = value.match(/\(([\d.]+),(\w+)\)/);
    if (match) {
      const amount = parseFloat(match[1]);
      const currency = match[2];
      return { amount, currency };
    }
    return { amount: 0, currency: 'USD' }; // default fallback
  }
  function formatCurrency(amount: number, currency: string) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 5,
    }).format(amount);
  }
  const parsedCostBasis = parseAmountWithCurrency(costBasis);
  const parsedPricePerShare = parseAmountWithCurrency(pricePerShare);

  return (
    <section className="card my-3">
      <Link
        to={`${explorerBaseUrl[chainId!]}/tx/${txHash}`}
        className="text-decoration-none"
        target="_blank"
      >
        <div className="d-flex align-items-center card-body">
          <div className="col-1">
            <img
              className="img-fluid border rounded-circle"
              width="50"
              src={logoUrl}
              alt="Corporate Logo"
            />
          </div>
          <div className="col-8">
            <h5>
              {issuer} <small className="text-muted">{shareClass}</small>
            </h5>
            <div>
              {label}{' '}
              <small className="text-muted">{balanceSecurityHash}</small>
            </div>
          </div>
          <div className="col-3 d-flex justify-content-end align-items-start text-end">
            <div>
              <div>
                {quantity !== undefined
                  ? numbro(quantity).format(formatOptions)
                  : '-'}{' '}
                shares
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer">
          <div className="d-flex justify-content-between text-secondary">
            <small>
              <span>
                Issue Date: {new Date(issueDate).toLocaleDateString()}
              </span>
            </small>
            <small>
              <span>
                Price Per Share:{' '}
                {formatCurrency(
                  parsedPricePerShare.amount,
                  parsedPricePerShare.currency
                )}
              </span>
            </small>
          </div>
        </div>
      </Link>
    </section>
  );
}
