import React, { useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { useOffering } from '../../../contexts/OfferingContext';

const CreateOfferingDocuments: React.FC = () => {
  const { auth } = useAuth();
  const { offeringId } = useParams();
  const { offering } = useOffering();
  const navigate = useNavigate();

  const [documents, setDocuments] = useState([
    { type: 'Material Agreement', file: null },
  ]);

  const handleFileChange = (index: number, event: React.ChangeEvent<any>) => {
    if (event.target.files) {
      const newDocuments = [...documents];
      newDocuments[index].file = event.target.files[0];
      setDocuments(newDocuments);
    }
  };

  const addDocument = () => {
    setDocuments([...documents, { type: 'Material Agreement', file: null }]);
  };

  const removeDocument = (index: number) => {
    const newDocuments = documents.slice();
    newDocuments.splice(index, 1);
    setDocuments(newDocuments);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const requiredDocs = [
        'Form C',
        'Financial Statements',
        'Subscription Agreement',
        'Pitch Deck',
        'Use of Funds Statement',
        'Risk Factors Document',
        'Capitalization Table',
      ];

      for (const doc of requiredDocs) {
        const fileInput = document.getElementById(
          doc.replace(/ /g, '_')
        ) as HTMLInputElement;

        if (fileInput && fileInput.files && fileInput.files[0]) {
          const formData = new FormData();
          formData.append('files', fileInput.files[0]);
          formData.append('type', doc);

          await axios({
            url: `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/documents`,
            method: 'POST',
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
            data: formData,
          });
        }
      }

      for (const document of documents) {
        if (document.file) {
          const formData = new FormData();
          formData.append('files', document.file);
          formData.append('type', document.type);

          await axios({
            url: `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/documents`,
            method: 'POST',
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
            data: formData,
          });
        }
      }
      navigate(`/offerings/${offeringId}/payment_terms/create`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container className="my-5">
      <div className="row">
        <div className="col-8 offset-2">
          <h2>Upload Offering Documents</h2>
          <Form onSubmit={handleSubmit}>
            {[
              'Form C',
              'Financial Statements',
              'Subscription Agreement',
              'Pitch Deck',
              'Use of Funds Statement',
              'Risk Factors Document',
              'Capitalization Table',
            ].map((docType, index) => (
              <div key={index}>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>{docType}</Form.Label>
                    <Form.Control
                      id={`${docType.replace(/ /g, '_')}`}
                      type="file"
                      required
                    />
                  </Form.Group>
                </Row>
              </div>
            ))}
            <h4>Additional Material Agreements and Contracts</h4>
            {documents.map((doc, index) => (
              <div key={index} className="mb-3 border p-3 rounded">
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>{doc.type}</Form.Label>
                    <Form.Control
                      id={`formGridDocumentFile${index}`}
                      type="file"
                      onChange={(e) => handleFileChange(index, e)}
                      required
                    />
                  </Form.Group>
                </Row>
                <Button variant="danger" onClick={() => removeDocument(index)}>
                  Remove Document
                </Button>
              </div>
            ))}
            <div className="d-flex justify-content-between align-items-center">
              <Button variant="secondary" onClick={addDocument}>
                Add Document
              </Button>
              <Button variant="primary" type="submit">
                Save and Continue
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </Container>
  );
};

export default CreateOfferingDocuments;
