import { useEffect, useState } from 'react';
import { Badge, Card, Button, ListGroup, Modal } from 'react-bootstrap';
import { contracts } from '../../constants';
import {
  useChainId,
  useAccount,
  usePublicClient,
  useReadContract,
  useWriteContract,
  useWaitForTransactionReceipt,
} from 'wagmi';
import { Abi } from 'viem';
import { formatUnits } from 'viem/utils';
import { decimals } from '../../utils';
import numbro from 'numbro';

type Listing = {
  id: number;
  baseToken: string;
  name: string;
  quantity: number;
  securityId: string;
  price: number;
  bids: Bid[];
  status: number;
};

type Bid = {
  id: number;
  listingId: number;
  token: string;
  price: string;
  amount: number;
  bidder: string;
  expiresAt: number;
  status: number;
};

export default function Listings() {
  const chainId = useChainId();
  const { address: account } = useAccount();
  const publicClient = usePublicClient();
  const [listings, setListings] = useState<Listing[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedBid, setSelectedBid] = useState<Bid | null>(null);

  const { data: userListings }: { data?: Listing[] } = useReadContract({
    address: contracts[chainId!]?.Auction?.address as `0x${string}`,
    abi: contracts[chainId!]?.Auction?.abi as Abi,
    functionName: 'getListingsByUser',
    args: [account],
  });

  /**
   * Fetch user listings and listing bids
   */
  useEffect(() => {
    const fetchBids = async (userListing: Listing) => {
      const bids = await publicClient?.readContract({
        address: contracts[chainId!].Auction.address as `0x${string}`,
        abi: contracts[chainId!].Auction.abi as Abi,
        functionName: 'getBidsByListingId',
        args: [userListing.id],
      });
      return (bids as Bid[]).filter(
        (bid) => bid.status !== 1 && bid.status !== 2
      );
    };

    const fetchListing = async (userListing: Listing) => {
      const listing = await publicClient?.readContract({
        address: contracts[chainId!].Auction.address as `0x${string}`,
        abi: contracts[chainId!].Auction.abi as Abi,
        functionName: 'getListingById',
        args: [userListing.id],
      });
      return listing as Listing;
    };

    if (!account || !userListings?.length) return;

    const fetchListingsAndBids = async () => {
      const listingsWithBids: Listing[] = await Promise.all(
        (userListings as Listing[]).map(async (userListing) => {
          const bids = await fetchBids(userListing);
          const listing = await fetchListing(userListing);
          return { ...listing, bids };
        })
      );
      setListings(listingsWithBids);
    };

    fetchListingsAndBids();
  }, [account, userListings, chainId, publicClient]);

  const statusEnumToText = (value: number) => {
    return {
      0: 'ACTIVE',
      1: 'WITHDRAWN',
      2: 'EXPIRED',
      3: 'PENDING',
      4: 'SOLD',
    }[value];
  };

  const handleAcceptBid = (bid: Bid, listingId: any) => {
    setSelectedBid({ ...bid, listingId: Number(listingId) });
    setShowModal(true);
  };

  const { writeContractAsync: acceptBid, data: acceptBidData } =
    useWriteContract();

  const { isLoading: isAcceptingBid, isError: isAcceptBidError } =
    useWaitForTransactionReceipt({
      hash: acceptBidData,
    });

  const confirmBidAcceptance = async () => {
    if (!selectedBid || !account) return;
    await acceptBid({
      address: contracts[chainId!].Auction.address as `0x${string}`,
      abi: contracts[chainId!].Auction.abi as Abi,
      functionName: 'acceptBid',
      args: selectedBid ? [selectedBid.listingId, selectedBid.id] : undefined,
    });
    if (!isAcceptingBid && !isAcceptBidError) {
      // After the bid is accepted, refetch the listings to get the updated status
      const updatedListing: any = await publicClient?.readContract({
        address: contracts[chainId!].Auction.address as `0x${string}`,
        abi: contracts[chainId!].Auction.abi as Abi,
        functionName: 'getListingById',
        args: [selectedBid.listingId],
      });
      const updatedListings = listings.map((listing) => {
        if (listing.id === selectedBid.listingId) {
          return { ...listing, status: updatedListing.status }; // assuming the contract returns the whole listing object
        }
        return listing;
      });
      setListings(updatedListings);

      console.log('Bid accepted successfully');
      setShowModal(false); // Close the modal after successful bid acceptance
      setSelectedBid(null); // Reset selected bid
    }
  };

  return (
    <>
      <h5>Your Listings</h5>
      {listings?.length === 0 && 'No listings to display'}
      <div className="card-container">
        {listings.map((listing, index) => (
          <Card key={index} className="mb-3">
            <Card.Header className="d-flex justify-content-between align-items-center">
              <div>
                Listing #{listing.id.toString()} - Token {listing.baseToken}
              </div>
              <div>
                <Button
                  variant="outline-secondary"
                  size="sm"
                  onClick={() =>
                    alert(
                      'Please contact support@capsign.com and provide the listing number to cancel.'
                    )
                  }
                >
                  <i className="fa fa-xmark" />
                </Button>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center m-2">
                <div className="col-3">
                  <strong>Quantity</strong>
                  <br />
                  {numbro(listing.quantity.toString()).format({
                    thousandSeparated: true,
                  })}{' '}
                  sh.
                </div>
                <div className="col-3">
                  <strong>Price</strong>
                  <br />
                  {numbro(
                    formatUnits(BigInt(listing.price), decimals[chainId!])
                  ).formatCurrency({ spaceSeparated: false, mantissa: 2 })}
                </div>
                <div className="col-3 d-inline-block text-truncate">
                  <strong>Security ID</strong>
                  <br />
                  <span className="">{listing.securityId}</span>
                </div>
                <div className="col-3 text-end">
                  <Badge>{statusEnumToText(listing.status)}</Badge>
                  {listing.status === 0 && listing.bids.length === 0 && (
                    <Badge bg="secondary" className="ms-2 text-uppercase">
                      No Bids
                    </Badge>
                  )}
                </div>
              </div>
              {listing.status !== 4 && listing.bids.length > 0 && (
                <>
                  <h5 className="mt-2">Bids</h5>
                  {listing.status === 3 ? (
                    'Listing is pending settlement by the issuing company.'
                  ) : (
                    <ListGroup variant="flush" className="border w-100">
                      {listing.bids.map((bid) => (
                        <ListGroup.Item key={bid.id.toString()}>
                          <div className="row">
                            <div className="col-5 align-self-center">
                              <div>Bidder: {bid.bidder}</div>
                            </div>
                            <div className="col-2 align-self-center">
                              <div>
                                Price: $
                                {formatUnits(
                                  BigInt(bid.price),
                                  decimals[chainId!]
                                )}
                                /sh
                              </div>
                            </div>
                            <div className="col-3 align-self-center">
                              <div>
                                Expiry:{' '}
                                {new Date(
                                  bid.expiresAt * 1000
                                ).toLocaleString()}
                              </div>
                            </div>
                            <div className="col-2 align-self-center text-end">
                              <Button
                                variant="primary"
                                size="sm"
                                onClick={() => handleAcceptBid(bid, listing.id)}
                              >
                                Accept Bid
                              </Button>
                            </div>
                          </div>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  )}
                </>
              )}
            </Card.Body>
          </Card>
        ))}
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Bid Acceptance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to accept this bid?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={confirmBidAcceptance}>
            Accept Bid
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
