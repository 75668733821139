import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import axios from 'axios';
import { useToast } from '../../../contexts/ToastContext';
import { useOnboarding } from '../../../contexts/OnboardingContext';
import { useError } from '../../../contexts/ErrorContext';
import * as ethers from 'ethers';
import { Spinner } from 'react-bootstrap';

export default function CustodialWallet() {
  const [wallet, setWallet] = useState<any>({});
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const { setMessage } = useToast();
  const { setError } = useError();
  const { auth } = useAuth();
  const { onboarding } = useOnboarding();

  /**
   * Fetch onboarding status
   */
  useEffect(() => {
    if (onboarding?.wallet) setWallet(onboarding.wallet);
  }, [onboarding]);

  /**
   * Poll balance
   */
  useEffect(() => {}, []);

  const createCustodialWallet = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/accounts/me/wallets`,
        {},
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      setWallet({ ...wallet, address: response.data.result });
    } catch (error) {
      console.error('Error creating custodial wallet:', error);
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(wallet.address);
    setMessage('Copied to clipboard!');
  };

  const checkBalance = async () => {
    const response = await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/v1/accounts/me/wallets/${wallet.address}`,
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    });
    const balance = response.data.result;
    const balanceInWei = ethers.utils.parseEther(balance);
    const minimumBalanceInWei = ethers.utils.parseEther('0.01');
    // Check if balance is greater than 0.01 ETH
    if (balanceInWei.gt(minimumBalanceInWei)) {
      console.log('Balance is greater than 0.01 ETH');
      // Proceed with your logic
    } else {
      setError('Not enough balance.');
    }
  };

  const createIdentity = async () => {
    try {
      setLoading(true);
      const response = await axios(
        `${process.env.REACT_APP_API_URL}/v1/accounts/me/wallets/${wallet.id}/identity`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
          data: {},
        }
      );
      console.log(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {!wallet.address && (
        <>
          <div className="alert alert-primary" role="alert">
            Custodial wallets are offered by{' '}
            <a href="https://www.palisade.co/" target="_blank">
              Palisade
            </a>
            . If you prefer to use your existing custodian, please contact{' '}
            <a href="mailto:support@capsign.com">support@capsign.com</a> with
            details.
          </div>
          <div className="form-check form-check-inline mb-4">
            <input
              className="form-check-input"
              type="checkbox"
              checked={isChecked}
              onChange={(e) => setIsChecked(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="flexCheckDefault">
              I have read and agree to CapSign's custodial wallet{' '}
              <a
                href="https://app.termly.io/policy-viewer/policy.html?policyUUID=45871e05-0337-4b1e-80f0-bae60d7df509"
                target="_blank"
              >
                terms of service
              </a>
              .
            </label>
          </div>
          <button
            className="btn btn-primary btn-lg"
            onClick={createCustodialWallet}
            disabled={!isChecked || loading}
          >
            Create Wallet
          </button>
        </>
      )}

      {wallet.address && !wallet?.funded && (
        <div className="mt-3">
          <h4>Fund wallet</h4>
          <p className="text-muted">
            Please send at least 0.1 ETH to your wallet.
          </p>
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              value={wallet.address}
              readOnly
            />
            <button
              className="btn btn-outline-secondary"
              onClick={copyToClipboard}
            >
              <i className="fas fa-copy"></i>
            </button>
          </div>
          <div className="mt-4">
            <button className="btn btn-primary" onClick={checkBalance}>
              Refresh Balance
            </button>
          </div>
        </div>
      )}

      {onboarding?.wallet?.funded && (
        <button onClick={createIdentity} className="btn btn-lg btn-primary">
          {loading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            'Create Identity'
          )}
        </button>
      )}
    </div>
  );
}
