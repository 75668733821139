import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PDFViewer from './PDFViewer';
import { useAuth } from '../../../contexts/AuthContext';
import { useParams } from 'react-router-dom';

export default function OfferingDetails() {
  const [pdfData, setPdfData] = useState<any>(undefined);
  const { auth } = useAuth();
  const { offeringId } = useParams();

  /**
   * Fetch the current offering document
   */
  useEffect(() => {
    const fetchOfferingDocuments = async () => {
      try {
        if (pdfData) return;

        let response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/documents`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        );

        // Pitch Deck
        const documentId = response.data.result.filter(
          (r: any) => r.document_type === 'Pitch Deck'
        )[0]?.document_id;

        response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/documents/${documentId}`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
            responseType: 'arraybuffer',
          }
        );
        setPdfData({ data: new Uint8Array(response.data) });
      } catch (error) {
        console.error('Error fetching offering:', error);
      }
    };

    fetchOfferingDocuments();
  }, []);

  return <PDFViewer data={pdfData} />;
}
