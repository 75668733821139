import { useEffect, useState } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import axios from 'axios';
import Stock from './Stock';
// import SAFE from './SAFE';
import { useChainId } from 'wagmi';

export default function View() {
  const { tokenId } = useParams();
  const { auth } = useAuth();
  const chainId = useChainId();
  const [token, setToken] = useState<any>();

  /**
   * Gets token
   */
  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/tokens/me?address=${tokenId}&chain_id=${chainId}`,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        );
        console.log(response.data.result);
        setToken(response.data.result);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return token?.asset_subtype === 'stock' ? (
    <>
      {/* Breadcrumb */}
      <div className="border-bottom">
        <div className="container-fluid py-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <NavLink to="/equity">Equity</NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {token.token_name}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <Stock token={token} />
    </>
  ) : (
    // <SAFE token={token} />
    <></>
  );
}
