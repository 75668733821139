import React from 'react';
import {
  Routes,
  Route,
  Link,
  Outlet,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import CustodialWallet from './CustodialWallet';
import SelfCustodyWallet from './SelfCustodyWallet';

export default function CreateIdentity(props: any) {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const walletType = searchParams.get('walletType');

  return (
    <div className="my-5">
      <div className="row">
        <div className="col-lg-8 offset-lg-2 text-center">
          {/* <div className="d-flex justify-content-center mb-4">
            <ul className="nav nav-underline">
              <li className="nav-item">
                <Link
                  className={`nav-link ${walletType === 'custodial' ? 'active' : ''}`}
                  to="?walletType=custodial"
                >
                  Custodial Wallet
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link ${walletType === 'self-custody' ? 'active' : ''}`}
                  to="?walletType=self-custody"
                >
                  Self-Custody Wallet
                </Link>
              </li>
            </ul>
          </div>
          <hr /> */}

          <div className="my-4">
            {walletType === 'custodial' && <CustodialWallet {...props} />}
            {walletType === 'self-custody' && <SelfCustodyWallet {...props} />}
          </div>
        </div>
      </div>
    </div>
  );
}
