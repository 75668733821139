// OfferingContext.tsx
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react';
import { useAuth } from './AuthContext';
import axios from 'axios';

type OfferingContextType = {
  offering: any;
  setOffering: (offering: object | null) => void;
  fetchOffering: (offeringId: string) => Promise<void>;
};

const OfferingContext = createContext<OfferingContextType | undefined>(
  undefined
);

export const useOffering = () => {
  const context = useContext(OfferingContext);
  if (context === undefined) {
    throw new Error('useOffering must be used within an OfferingProvider');
  }
  return context;
};

interface OfferingProviderProps {
  children: ReactNode;
}

export const OfferingProvider: React.FC<OfferingProviderProps> = ({
  children,
}) => {
  const { auth } = useAuth();
  const [offering, setOffering] = useState<object>();

  const fetchOffering = async (offeringId: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      setOffering(response.data.result);
    } catch (error) {
      console.error('Failed to fetch offering', error);
    }
  };

  const value = { offering, setOffering, fetchOffering };

  return (
    <OfferingContext.Provider value={value as any}>
      {children}
    </OfferingContext.Provider>
  );
};
